import * as PIXI from "pixi.js";

import "@pixi/math-extras";
import "@pixi/math";

import * as entity from "booyah/src/entity";
import * as audio from "booyah/src/audio";
import * as input from "booyah/src/input";
import * as util from "booyah/src/util";

import * as runner from "./runner";
import * as loader from "./loader";
import * as level from "./level";
import * as assets from "./assets";
import * as settings from "./settings";
import * as gameOver from "./game_over";
import * as tools from "./tools";

const savedSettings = settings.getLocalSettings();
const language = settings.getLanguage();

// Start loading video
{
  const videoElement = document.getElementById(
    "intro-video"
  ) as HTMLVideoElement;
  videoElement.src = assets.videoSources[language as "en"].pathname;
}

// // Change link to website if language is French
// if (language === "fr") {
//   const playCuriousLink = document.getElementById(
//     "play-curious-link"
//   ) as HTMLAnchorElement;
//   playCuriousLink.href = "https://playcurious.games/fr/";
// }

const gameStates = {
  start: new level.Level(),
  endScreen: new gameOver.EndScreen(),
  leaderboard: new gameOver.LeaderboardScreen(),
};

function installInput(
  rootConfig: entity.EntityConfig,
  rootEntity: entity.ParallelEntity
): void {
  const keyboard = new input.Keyboard();
  rootEntity.addChildEntity(keyboard);
  rootConfig.keyboard = keyboard;
}

runner.startLoading({
  loadingEntity: new loader.LoadingScene(),
  preloaderAssets: assets.preloaderAssets,
  transitions: {
    start: entity.makeTransition("endScreen"),
    endScreen: entity.makeTransition("leaderboard"),
    leaderboard: entity.makeTransition("start"),
  },
  states: gameStates,
  fontAssets: Object.values(assets.Fonts),
  screenSize: tools.screenSize,
  entityInstallers: [
    installInput,
    audio.makeInstallJukebox({ volume: savedSettings.music }),
    audio.makeInstallFxMachine({ volume: savedSettings.sound }),
    settings.installSettingsButton,
  ],
  graphicalAssets: assets.graphicalAssets,
  musicAssets: assets.musicAssets,
  fxAssets: assets.fxAssets,
});

// Have the HTML layer match the canvas scale and x-offset
function resizeHtmlLayer(appSize: PIXI.Point): void {
  const canvasBbox = document
    .getElementById("pixi-canvas")
    .getBoundingClientRect();
  const canvasSize = new PIXI.Point(canvasBbox.width, canvasBbox.height);
  const scale = util.toFixedFloor(
    Math.min(canvasSize.x / appSize.x, canvasSize.y / appSize.y),
    3
  );
  const offset = util.toFixedFloor(canvasBbox.left, 2);

  const container = document.getElementById("html-layer");
  const transformCss = `translate(${offset}px, 0px) scale(${scale})`;
  for (const prop of ["transform", "webkitTransform", "msTransform"]) {
    // @ts-ignore
    container.style[prop] = transformCss;
  }
}

// Resize now, and force the resize to happen when the window size changes
resizeHtmlLayer(tools.screenSize);
window.addEventListener("resize", () => resizeHtmlLayer(tools.screenSize));
document.addEventListener("fullscreenchange", () =>
  resizeHtmlLayer(tools.screenSize)
);
