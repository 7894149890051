import * as planck from "planck";
import * as PIXI from "pixi.js";

import * as entity from "booyah/src/entity";

import * as waste from "./waste";
import * as settings from "./settings";
import * as tools from "./tools";
import * as boat from "./boat";
import * as map from "./map";

const emptyNetRadius = 2;
const fullNetRadius = tools.toMeters(150);
const maxDistanceFromBoat = 15;

const initialWastesInNetLimit = 5;
const maxWastesInNetLimit = Math.floor(initialWastesInNetLimit * 2.5);

export type NetState = "normal" | "invulnerable";

export class Net extends entity.CompositeEntity {
  private _container: PIXI.Container;

  private _backContainer: PIXI.Container;
  private _wasteContainer: PIXI.Container;
  private _gaugeContainer: PIXI.Container;

  private _state: NetState;
  private _wastesLimit: number;
  private _position: planck.Vec2;
  private _direction: number;
  private _wastes: waste.WasteInNet[];
  private _radius: number;
  private _distanceFromBoat: number;
  private _netLimit: tools.FreeSprite;
  private _netGauge: tools.FreeSprite;
  private _netHint: entity.AnimatedSpriteEntity;
  private _netDebug: PIXI.Graphics;
  private _invulnerableElapsedTime: number;

  private _body: planck.Body;
  private _fixture: planck.Fixture;
  private _jointA: planck.RopeJoint;
  private _jointB: planck.RopeJoint;

  get position() {
    return this._position;
  }

  get collected() {
    return this._wastes;
  }

  get boat(): boat.Boat {
    return this._entityConfig.level.map.boat;
  }

  get globalCompletion(): number {
    return this._wastes.length / maxWastesInNetLimit;
  }

  protected _setup(): void {
    this._position = new planck.Vec2();
    this._direction = 0;
    this._wastes = [];
    this._wastesLimit = initialWastesInNetLimit;
    this._state = "normal";
    this._radius = emptyNetRadius;
    this._distanceFromBoat = 3 * emptyNetRadius;
    this._position = this.boat.position
      .clone()
      .sub(new planck.Vec2(this._distanceFromBoat, 0));

    // Setup physics
    const userData: map.BodyUserData = {
      type: "net",
      entity: this,
    };
    this._body = this._entityConfig.world.createBody({
      type: "dynamic",
      position: this._position,
      linearDamping: 1,
      angularDamping: 1,
      userData,
    });

    // Setup graphics
    this._container = new PIXI.Container();

    this._backContainer = new PIXI.Container();
    this._wasteContainer = new PIXI.Container();
    this._gaugeContainer = new PIXI.Container();

    this._container.addChild(
      this._backContainer,
      this._wasteContainer,
      this._gaugeContainer
    );

    this._container.position = tools.toPixiPoint(this._position);
    this._entityConfig.container.addChild(this._container);

    // Draw net
    this._netLimit = this._backContainer.addChild(
      tools.freeSprite(this, "net_limit", (it) => {
        it.completion = 1;
        it.anchor.set(0.5, 0.365);
        it.angle = 90;
      })
    );

    // Draw gauge
    this._netGauge = this._gaugeContainer.addChild(
      tools.freeSprite(this, "net_gauge", (it) => {
        it.completion = 0;
        it.scale.set(0.5);
        it.anchor.set(0.5);
      })
    );

    // Draw hint
    this._netHint = tools.animatedSprite(this, `net_hint`, {}, (it) => {
      it.sprite.animationSpeed = 25 / 60;
      it.sprite.loop = true;
      it.sprite.anchor.set(0.5);
      it.sprite.angle = 90;
      it.sprite.position.x = 40;

      this._activateChildEntity(
        it,
        entity.extendConfig({
          container: this._container,
        })
      );
    });

    // Draw debug
    if (settings.inDebugMode()) {
      this._netDebug = new PIXI.Graphics();
      this._container.addChild(this._netDebug);
    }

    this._updateRadius();
  }

  protected _teardown(): void {
    this._entityConfig.world.destroyBody(this._body);
    delete this._body;

    this._entityConfig.container.removeChild(this._container);
    delete this._container;
  }

  protected _update(): void {
    this._position = this._body.getPosition();
    this._container.position = tools.toPixiPoint(this._position);

    this._direction = this._body.getAngle();
    this._container.rotation = this._direction;
    this._netGauge.rotation = -this._direction;

    if (this._state === "invulnerable") {
      this._invulnerableElapsedTime += this._lastFrameInfo.timeSinceLastFrame;

      if (this._invulnerableElapsedTime >= boat.invulerableTime)
        this._state = "normal";
    }

    this._netHint.sprite.scale.set(
      Math.max(0.25, this.globalCompletion * 0.5) + 0.3
    );
  }

  /** Returns true if the trash can be collected */
  collectTrash(trash: waste.Waste): boolean {
    if (this._state !== "normal") return false;

    console.log("collected trash", this._wastes.length + 1);

    // Find random position for trash at back of net
    const trashDistance =
      0.5 * this._radius + Math.random() * 0.5 * this._radius;
    const trashAngle = 0.75 * Math.PI + Math.random() * 0.5 * Math.PI;
    const trashPosition = new planck.Vec2(
      trashDistance * Math.cos(trashAngle),
      trashDistance * Math.sin(trashAngle)
    );

    const wasteInNet = new waste.WasteInNet(
      trashPosition,
      trash.radius,
      trash.name
    );

    this._wastes.push(wasteInNet);
    this._activateChildEntity(
      wasteInNet,
      entity.extendConfig({ container: this._wasteContainer, net: this })
    );

    this._updateRadius();
    this._pushApartWastes();

    this.emit("collected", trash);

    this._animateCollect();

    if (this._wastes.length >= this._wastesLimit) {
      this._emptyFullNet();
    }

    return true;
  }

  private _animateCollect() {
    this._activateChildEntity(
      tools.animatedSprite(
        this,
        `net_input`,
        { transitionOnComplete: true },
        (it) => {
          it.sprite.animationSpeed = 25 / 60;
          it.sprite.loop = false;
          it.sprite.anchor.set(0.5);
        }
      ),
      entity.extendConfig({
        container: this._netHint.sprite,
      })
    );
  }

  private _updateRadius(): void {
    // Determine the radius from the area of the number of wastes
    let wasteArea = 0;
    for (const waste of this._wastes) {
      wasteArea += Math.PI * waste.radius * waste.radius;
    }

    // The emptyNetRadius is the smallest it can be
    this._radius = Math.max(emptyNetRadius, Math.sqrt(wasteArea / Math.PI));

    // Update physics
    if (this._fixture) {
      this._body.destroyFixture(this._fixture);
    }
    this._fixture = this._body.createFixture(new planck.Circle(this._radius), {
      density: 1,
      filterCategoryBits: map.BodyCategoryBits.net,
    });

    // Update sprite
    this._netLimit.gotoProportion(this._radius, fullNetRadius, emptyNetRadius);
    this._netGauge.completion = this._wastes.length / this._wastesLimit;

    this._updateDistanceFromBoat();

    this.emit("completionUpdated", this._netLimit.completion);
  }

  private _updateDistanceFromBoat(): void {
    this._distanceFromBoat = Math.min(3 * this._radius, maxDistanceFromBoat);
    console.log("net distance from boat", this._distanceFromBoat);

    // Destroy old joints
    if (this._jointA) {
      this._entityConfig.world.destroyJoint(this._jointA);
      this._entityConfig.world.destroyJoint(this._jointB);
    }

    const boat: boat.Boat = this._entityConfig.level.map.boat;

    // Make upper joint
    {
      // The point on the net attached to the boat
      const netAttachPosition = new planck.Vec2(
        this._radius * Math.cos(Math.PI / 4),
        this._radius * Math.sin(Math.PI / 4)
      );
      this._jointA = this._entityConfig.world.createJoint(
        new planck.RopeJoint({
          bodyA: this._body,
          bodyB: boat.body,
          localAnchorA: netAttachPosition,
          localAnchorB: new planck.Vec2(), // Center of boat
          maxLength: this._distanceFromBoat,
          collideConnected: true,
        })
      );
    }

    // Make lower joint
    {
      // The point on the net attached to the boat
      const netAttachPosition = new planck.Vec2(
        this._radius * Math.cos(-Math.PI / 4),
        this._radius * Math.sin(-Math.PI / 4)
      );
      this._jointB = this._entityConfig.world.createJoint(
        new planck.RopeJoint({
          bodyA: this._body,
          bodyB: boat.body,
          localAnchorA: netAttachPosition,
          localAnchorB: new planck.Vec2(), // Center of boat
          maxLength: this._distanceFromBoat,
          collideConnected: true,
        })
      );
    }

    // Update debug graphics
    if (this._netDebug) {
      this._netDebug.clear();
      this._netDebug.beginFill(0xffffff, 0.5);
      this._netDebug.drawCircle(0, 0, tools.toPixels(this._radius));
      this._netDebug.endFill();

      const netAttachPosition = new planck.Vec2(this._radius, 0);
      const netAttachPosInPixels = tools.toPixiPoint(netAttachPosition);
      this._netDebug.beginFill(0xffffff, 0.5);
      this._netDebug.drawCircle(
        netAttachPosInPixels.x,
        netAttachPosInPixels.y,
        10
      );
      this._netDebug.endFill();
    }
  }

  private _pushApartWastes(): void {
    // Go through each pair of wastes, accumulating forces
    for (let i = 0; i < this._wastes.length - 1; i++) {
      const a = this._wastes[i];
      for (let j = i + 1; j < this._wastes.length; j++) {
        const b = this._wastes[j];

        const currentDistance = planck.Vec2.distance(a.position, b.position);
        const neededDistance = a.radius + b.radius;
        if (currentDistance < neededDistance) {
          // Add half the distance needed, and divide by the current distance to normalize the vector
          const forceScalar =
            (0.5 * (neededDistance - currentDistance)) / currentDistance;
          const forceOnA = planck.Vec2.sub(a.position, b.position).mul(
            forceScalar
          );
          a.addForce(forceOnA);

          // Add the opposite force to B
          b.addForce(planck.Vec2.mul(forceOnA, -1));
        }
      }
    }

    // Apply forces
    for (const waste of this._wastes) {
      waste.applyForces();
    }
  }

  private _emptyFullNet(): void {
    this._netGauge.completion = 0;

    this.emit("isFull", this._wastes);

    this._wastes.forEach((item) => {
      this._deactivateChildEntity(item);
    });

    this._wastes = [];

    this._updateRadius();

    this._activateChildEntity(
      tools.animatedSprite(
        this,
        "net_gauge_avoid",
        { transitionOnComplete: true },
        (it) => {
          it.sprite.anchor.set(0.5);
          it.sprite.animationSpeed = 25 / 60;
          it.sprite.loop = false;
        }
      ),
      entity.extendConfig({
        container: this._netGauge,
      })
    );
  }

  get radius(): number {
    return this._radius;
  }

  get collisionArea(): PIXI.Circle {
    return new PIXI.Circle(this._position.x, this._position.y, this._radius);
  }

  get state(): NetState {
    return this._state;
  }

  get body(): planck.Body {
    return this._body;
  }

  get screenPosition(): PIXI.Point {
    return this._container.getGlobalPosition();
  }

  /** Returns true if collision is taken into account, else false */
  takeCollision(): boolean {
    if (this._state !== "normal") return false;

    this._invulnerableElapsedTime = 0;
    this._state = "invulnerable";

    for (const waste of this._wastes) {
      this.emit("released", waste);

      this._deactivateChildEntity(waste);
    }

    this._wastes = [];

    this._updateRadius();

    return true;
  }

  levelUp(): void {
    this._wastesLimit += 3;

    if (this._wastesLimit > maxWastesInNetLimit)
      this._wastesLimit = Math.floor(maxWastesInNetLimit);

    console.log("net size", this._wastesLimit);
  }
}
