import * as PIXI from "pixi.js";

import * as entity from "booyah/src/entity";

import * as runner from "./runner";
import * as assets from "./assets";
import * as tools from "./tools";

export class LoadingScene extends entity.EntityBase {
  static readonly interiorWidth = 1420;

  private _container: PIXI.Container;

  private _videoElement: HTMLVideoElement;
  private _playVideoButton: HTMLDivElement;
  private _onPlayVideoButtonClick: EventListener;
  private _onVideoEnd: EventListener;

  private _playButton: PIXI.Sprite;
  private _playButtonText: PIXI.Text;

  private _progressContainer: PIXI.Container;
  private _progressText: PIXI.Text;
  private _progressBarInside: PIXI.Sprite;
  private _progressBarInsideMask: PIXI.Graphics;

  protected _setup(): void {
    const emitter = this._entityConfig.loadingEventEmitter;
    this._on(emitter, "progress", this._onProgress);
    this._on(emitter, "error", this._onError);
    this._on(emitter, "done", this._onDone);

    this._container = new PIXI.Container();
    this._entityConfig.container.addChild(this._container);

    // HMTL video
    {
      this._videoElement = document.getElementById(
        "intro-video"
      ) as HTMLVideoElement;

      // When the video ends, show play button again
      this._onVideoEnd = () => this._setPlayVideoButtonVisibility(true);
      this._videoElement.addEventListener("ended", this._onVideoEnd);
    }

    // HTML play video button
    {
      this._playVideoButton = document.getElementById(
        "play-button"
      ) as HTMLDivElement;

      this._onPlayVideoButtonClick = () => {
        // if (!this._canPlayThrough) return;

        this._videoElement
          .play()
          .then(() => this._setPlayVideoButtonVisibility(false))
          .catch((e) => console.error("Caught error playing video", e));
      };

      this._playVideoButton.addEventListener(
        "click",
        this._onPlayVideoButtonClick
      );
    }

    // Background
    this._container.addChild(
      new PIXI.Sprite(
        this._entityConfig.preloader.resources[
          assets.preloaderImages["loader_bg"].pathname
        ].texture
      )
    );

    const marginX = (tools.screenSize.x - LoadingScene.interiorWidth) / 2;

    this._progressContainer = new PIXI.Container();
    this._container.addChild(this._progressContainer);

    // Progress bar outside
    {
      const progressBarOutside = new PIXI.NineSlicePlane(
        this._entityConfig.preloader.resources[
          assets.preloaderImages["rounded_17_white"].pathname
        ].texture,
        17,
        17,
        17,
        17
      );
      progressBarOutside.width = LoadingScene.interiorWidth;
      progressBarOutside.height = 50;
      progressBarOutside.position.set(marginX, 880);
      this._progressContainer.addChild(progressBarOutside);
    }

    // Progress bar inside
    {
      this._progressBarInside = new PIXI.Sprite(
        this._entityConfig.preloader.resources[
          assets.preloaderImages["loader_gradient"].pathname
        ].texture
      );
      this._progressBarInside.position.set(marginX + 7, 880 + 7);
      this._progressContainer.addChild(this._progressBarInside);

      this._progressBarInsideMask = new PIXI.Graphics();
      this._progressBarInside.addChild(this._progressBarInsideMask);
      this._progressBarInside.mask = this._progressBarInsideMask;
    }

    // Progress text
    {
      this._progressText = tools.text(" 0% ", {
        fill: "white",
        fontFamily: "sans-serif",
        fontSize: 40,
      });
      this._progressText.anchor.set(1, 0);
      this._progressText.position.set(
        marginX + LoadingScene.interiorWidth,
        980
      );
      this._progressContainer.addChild(this._progressText);
    }

    // Play Button (created here but not added)
    {
      this._playButton = new PIXI.Sprite(
        this._entityConfig.preloader.resources[
          assets.preloaderImages["play_game_button"].pathname
        ].texture
      );
      this._playButton.position.set(
        marginX + LoadingScene.interiorWidth - this._playButton.width,
        920
      );
      this._playButton.interactive = true;
      this._playButton.buttonMode = true;
      this._on(this._playButton, "pointertap", this._onClickPlay);
      this._on(this._playButton, "pointerover", () =>
        this._changePlayButtonMode(true)
      );
      this._on(this._playButton, "pointerout", () =>
        this._changePlayButtonMode(false)
      );

      this._playButtonText = this._playButton.addChild(
        tools.text(
          ` ${tools.translate("play").toUpperCase()} `,
          {
            fill: "white",
            fontFamily: assets.Fonts.Nexa,
            fontSize: 70,
          },
          (it) => {
            it.position.set(
              this._playButton.width / 2,
              this._playButton.height / 2
            );
            it.anchor.set(0.5);
          }
        )
      );
    }
  }

  protected _teardown(): void {
    this._entityConfig.container.removeChild(this._container);

    // Remove video element embedded in HTML
    document.getElementById("intro-video")?.remove();

    this._setPlayVideoButtonVisibility(false);

    this._playVideoButton.removeEventListener(
      "click",
      this._onPlayVideoButtonClick
    );
    this._videoElement.removeEventListener("ended", this._onVideoEnd);
  }

  private _onProgress(progress: number): void {
    const progressPercent = Math.round(progress * 100);
    this._progressText.text = ` ${progressPercent}% `;

    this._progressBarInsideMask.clear();
    this._progressBarInsideMask.beginFill(0xffffff);
    this._progressBarInsideMask.drawRoundedRect(
      0,
      0,
      progress * this._progressBarInside.width,
      this._progressBarInside.height,
      17
    );
    this._progressBarInsideMask.endFill();
  }

  private _onError(err: Error): void {
    // TODO
    console.log("Loader error", err);
  }

  private _onDone(): void {
    console.log("Loader done");

    this._container.removeChild(this._progressContainer);
    this._container.addChild(this._playButton);
  }

  private _onClickPlay(): void {
    runner.startGame();
  }

  private _changePlayButtonMode(hover: boolean): void {
    if (hover) {
      this._playButton.texture =
        this._entityConfig.preloader.resources[
          assets.preloaderImages["play_game_button_hover"].pathname
        ].texture;
      this._playButtonText.style.fill = 0xf72717;
    } else {
      this._playButton.texture =
        this._entityConfig.preloader.resources[
          assets.preloaderImages["play_game_button"].pathname
        ].texture;
      this._playButtonText.style.fill = "white";
    }
  }

  private _setPlayVideoButtonVisibility(visible: boolean): void {
    this._playVideoButton.style.display = visible ? "block" : "none";
  }
}
