// Maps image names to their bundled filenames
export const preloaderImages = {
  loader_bg: new URL("../images/loader_bg.png", import.meta.url),
  loader_gradient: new URL("../images/loader_gradient.png", import.meta.url),
  rounded_17_white: new URL("../images/rounded_17_white.png", import.meta.url),
  play_game_button: new URL("../images/play_game_button.png", import.meta.url),
  play_game_button_hover: new URL(
    "../images/play_game_button_hover.png",
    import.meta.url
  ),
};

export const preloaderAssets = getStrings(preloaderImages);

// Maps image names to their bundled filenames
export const images = {
  life: new URL("../images/life.png", import.meta.url),
  red_bar: new URL("../images/red_bar.png", import.meta.url),
  rounded_100_white: new URL(
    "../images/rounded_100_white.png",
    import.meta.url
  ),
  rounded_17_cyan: new URL("../images/rounded_17_cyan.png", import.meta.url),
  rounded_button_44: new URL(
    "../images/rounded_button_44.png",
    import.meta.url
  ),
  rounded_button_44_hover: new URL(
    "../images/rounded_button_44_hover.png",
    import.meta.url
  ),
  close: new URL("../images/close.png", import.meta.url),
  close_pressed: new URL("../images/close_pressed.png", import.meta.url),
  fullscreen_on: new URL("../images/fullscreen_on.png", import.meta.url),
  fullscreen_off: new URL("../images/fullscreen_off.png", import.meta.url),
  heart: new URL("../images/heart.png", import.meta.url),
  music: new URL("../images/music.png", import.meta.url),
  music_muted: new URL("../images/music_muted.png", import.meta.url),
  sound: new URL("../images/sound.png", import.meta.url),
  sound_muted: new URL("../images/sound_muted.png", import.meta.url),
  play: new URL("../images/play.png", import.meta.url),
  settings: new URL("../images/settings.png", import.meta.url),
  settings_pressed: new URL("../images/settings_pressed.png", import.meta.url),
  baril: new URL("../images/baril.png", import.meta.url),
  bottle: new URL("../images/bottle.png", import.meta.url),
  can: new URL("../images/can.png", import.meta.url),
  plastic_bag: new URL("../images/plastic_bag.png", import.meta.url),
  range_dot: new URL("../images/range_dot.png", import.meta.url),
  button_off: new URL("../images/button_off.png", import.meta.url),
  button_on: new URL("../images/button_on.png", import.meta.url),
  tuto_control_joystick: new URL(
    "../images/tuto_control_joystick.png",
    import.meta.url
  ),
  tuto_control_keyboard: new URL(
    "../images/tuto_control_keyboard.png",
    import.meta.url
  ),
  tuto_obstacle: new URL("../images/tuto_obstacle.png", import.meta.url),
  tuto_waste: new URL("../images/tuto_waste.png", import.meta.url),
  on_iceberg_igloo_left: new URL(
    "../images/on_iceberg_igloo_left.png",
    import.meta.url
  ),
  on_iceberg_igloo_right: new URL(
    "../images/on_iceberg_igloo_right.png",
    import.meta.url
  ),
  on_iceberg_transat_left: new URL(
    "../images/on_iceberg_transat_left.png",
    import.meta.url
  ),
  on_iceberg_transat_right: new URL(
    "../images/on_iceberg_transat_right.png",
    import.meta.url
  ),
  seagull: new URL("../images/seagull.png", import.meta.url),
  seagull_shadow: new URL("../images/seagull_shadow.png", import.meta.url),
} as const;

// Maps spritesheet names to their bundled filenames
export const spritesheets = {
  boat: new URL("../images/boat.json", import.meta.url),
  boat_hit: new URL("../images/boat_hit.json", import.meta.url),
  boat_trail: new URL("../images/boat_trail.json", import.meta.url),
  boat_waves: new URL("../images/boat_waves.json", import.meta.url),
  net_gauge: new URL("../images/net_gauge.json", import.meta.url),
  net_gauge_avoid: new URL("../images/net_gauge_avoid.json", import.meta.url),
  net_limit: new URL("../images/net_limit.json", import.meta.url),
  net_rope: new URL("../images/net_rope.json", import.meta.url),
  dolphin_hit: new URL("../images/dolphin_hit.json", import.meta.url),
  dolphin_swim: new URL("../images/dolphin_swim.json", import.meta.url),
  penguin_hit: new URL("../images/penguin_hit.json", import.meta.url),
  penguin_swim: new URL("../images/penguin_swim.json", import.meta.url),
  whale_hit: new URL("../images/whale_hit.json", import.meta.url),
  whale_swim: new URL("../images/whale_swim.json", import.meta.url),
  waste_baril: new URL("../images/waste_baril.json", import.meta.url),
  waste_bottle: new URL("../images/waste_bottle.json", import.meta.url),
  waste_can: new URL("../images/waste_can.json", import.meta.url),
  waste_plastic_bag: new URL(
    "../images/waste_plastic_bag.json",
    import.meta.url
  ),
  picked_up_baril: new URL("../images/picked_up_baril.json", import.meta.url),
  picked_up_bottle: new URL("../images/picked_up_bottle.json", import.meta.url),
  picked_up_can: new URL("../images/picked_up_can.json", import.meta.url),
  picked_up_plastic_bag: new URL(
    "../images/picked_up_plastic_bag.json",
    import.meta.url
  ),
  score_25: new URL("../images/score_25.json", import.meta.url),
  score_50: new URL("../images/score_50.json", import.meta.url),
  score_75: new URL("../images/score_75.json", import.meta.url),
  score_100: new URL("../images/score_100.json", import.meta.url),
  score_250: new URL("../images/score_250.json", import.meta.url),
  score_background: new URL("../images/score_background.json", import.meta.url),
  iceberg_bean: new URL("../images/iceberg_bean.json", import.meta.url),
  iceberg_big: new URL("../images/iceberg_big.json", import.meta.url),
  iceberg_boomerang: new URL(
    "../images/iceberg_boomerang.json",
    import.meta.url
  ),
  iceberg_medium: new URL("../images/iceberg_medium.json", import.meta.url),
  iceberg_small: new URL("../images/iceberg_small.json", import.meta.url),
  net_input: new URL("../images/net_input.json", import.meta.url),
  net_hint: new URL("../images/net_hint.json", import.meta.url),
  buoy_0: new URL("../images/buoy_0.json", import.meta.url),
  buoy_1: new URL("../images/buoy_1.json", import.meta.url),
  on_iceberg_penguin_stay_N: new URL(
    "../images/on_iceberg_penguin_stay_N.json",
    import.meta.url
  ),
  on_iceberg_penguin_stay_S: new URL(
    "../images/on_iceberg_penguin_stay_S.json",
    import.meta.url
  ),
  on_iceberg_penguin_stay_E: new URL(
    "../images/on_iceberg_penguin_stay_E.json",
    import.meta.url
  ),
  on_iceberg_penguin_stay_W: new URL(
    "../images/on_iceberg_penguin_stay_W.json",
    import.meta.url
  ),
  on_iceberg_penguin_stay_NE: new URL(
    "../images/on_iceberg_penguin_stay_NE.json",
    import.meta.url
  ),
  on_iceberg_penguin_stay_NW: new URL(
    "../images/on_iceberg_penguin_stay_NW.json",
    import.meta.url
  ),
  on_iceberg_penguin_stay_SE: new URL(
    "../images/on_iceberg_penguin_stay_SE.json",
    import.meta.url
  ),
  on_iceberg_penguin_walk: new URL(
    "../images/on_iceberg_penguin_walk.json",
    import.meta.url
  ),
  on_iceberg_penguin_walk_reversed: new URL(
    "../images/on_iceberg_penguin_walk_reversed.json",
    import.meta.url
  ),
  on_iceberg_palm: new URL("../images/on_iceberg_palm.json", import.meta.url),
} as const;

function getStrings(urls: Record<string, URL>): string[] {
  return Object.values(urls).map((url) => url.pathname);
}

export const graphicalAssets = [
  ...getStrings(images),
  ...getStrings(spritesheets),
];

export type SpriteSheetName = keyof typeof spritesheets;
export type SpriteName = keyof typeof images;

export enum Fonts {
  Nexa = "Nexa Extra Light",
  NexaHeavy = "Nexa Heavy",
  Ubuntu = "Ubuntu",
  AmericanTypewriter = "American Typewriter",
}

export const musicTracks = {
  theme: new URL("../audio/music/red_boat_quest.mp3", import.meta.url),
} as const;

export type MusicName = keyof typeof musicTracks;

export const musicAssets = getStrings(musicTracks);

export const fxTracks = {
  points: new URL("../audio/fx/points.mp3", import.meta.url),
  bonus: new URL("../audio/fx/bonus.mp3", import.meta.url),
  button: new URL("../audio/fx/button.mp3", import.meta.url),
  end: new URL("../audio/fx/end.mp3", import.meta.url),
  hit_moving_obstacle: new URL(
    "../audio/fx/hit_moving_obstacle.mp3",
    import.meta.url
  ),
  hit_static_obstacle: new URL(
    "../audio/fx/hit_static_obstacle.mp3",
    import.meta.url
  ),
  options: new URL("../audio/fx/options.mp3", import.meta.url),
  dolphin: new URL("../audio/fx/dolphin.mp3", import.meta.url),
  penguin: new URL("../audio/fx/penguin.mp3", import.meta.url),
  whale: new URL("../audio/fx/whale.mp3", import.meta.url),
  waste: new URL("../audio/fx/waste.mp3", import.meta.url),
  boat: new URL("../audio/fx/boat_loop.mp3", import.meta.url),
} as const;

export type FXName = keyof typeof fxTracks;

export const fxAssets = getStrings(fxTracks);

export const videoSources = {
  en: new URL("../videos/intro-en.mp4", import.meta.url),
  // fr: new URL("../videos/intro-fr.mp4", import.meta.url),
  // es: new URL("../videos/intro-es.mp4", import.meta.url),
  // pt: new URL("../videos/intro-pt.mp4", import.meta.url),
} as const;
