import * as PIXI from "pixi.js";

import * as entity from "booyah/src/entity";
import * as geom from "booyah/src/geom";

import * as boat from "./boat";

const inputDeadZone = 10;

export class InputHandler extends entity.EntityBase {
  private _hitBox: PIXI.Container;
  private _pointerDownPos?: PIXI.Point;
  private _pointerMovePos?: PIXI.Point;
  private _container: PIXI.Container;
  private _inCircle: PIXI.Graphics;

  protected _setup(): void {
    this._hitBox = new PIXI.Sprite();
    this._hitBox.hitArea = new PIXI.Rectangle(
      0,
      0,
      this._entityConfig.app.view.width,
      this._entityConfig.app.view.height
    );
    this._hitBox.interactive = true;
    this._entityConfig.container.addChild(this._hitBox);

    this._container = new PIXI.Container();
    this._container.visible = false;
    const outCircle = new PIXI.Graphics()
      .lineStyle(30, 0xffffff, 0.5)
      .drawCircle(0, 0, 100)
      .endFill();
    this._inCircle = new PIXI.Graphics()
      .beginFill(0xffffff, 0.5)
      .drawCircle(0, 0, 50)
      .endFill();
    outCircle.addChild(this._inCircle);
    this._container.addChild(outCircle);
    this._entityConfig.container.addChild(this._container);

    this._on(this._hitBox, "pointerdown", this._onPointerDown);
    this._on(this._hitBox, "pointerup", this._onPointerUp);
    this._on(this._hitBox, "pointermove", this._onPointerMove);
    this._on(this._hitBox, "pointerout", this._onPointerUp);
  }

  protected _update(): void {
    this._handleKeyboardInput();

    if (!this._pointerDownPos) return;

    const distance = geom.distance(this._pointerDownPos, this._pointerMovePos);
    this._inCircle.position.set(0, 0); // Snap joystick to center
    if (distance < inputDeadZone) return;

    const delta = new PIXI.Point(
      this._pointerMovePos.x - this._pointerDownPos.x,
      this._pointerMovePos.y - this._pointerDownPos.y
    );

    const angle = Math.atan2(delta.y, delta.x);

    const joystickDistance = Math.min(distance, 35);
    this._inCircle.position.set(
      Math.cos(angle) * joystickDistance,
      Math.sin(angle) * joystickDistance
    );

    this._entityConfig.level.map.boat.turnTo(angle);
  }

  protected _teardown(): void {
    this._entityConfig.container.removeChild(this._hitBox, this._container);
  }

  private _onPointerDown(event: PIXI.InteractionEvent): void {
    this._pointerDownPos = event.data.global.clone();
    this._pointerMovePos = event.data.global.clone();

    this._container.position.copyFrom(event.data.global);
    this._inCircle.position.set(0, 0);
    this._container.visible = true;
  }

  private _onPointerUp(event: PIXI.InteractionEvent): void {
    delete this._pointerDownPos;
    delete this._pointerMovePos;

    this._container.visible = false;
  }

  private _onPointerMove(event: PIXI.InteractionEvent): void {
    if (!this._pointerDownPos) return;

    this._pointerMovePos = event.data.global.clone();
  }

  private _handleKeyboardInput(): void {
    const keysDown = this._entityConfig.keyboard.keysDown;
    const boatEntity: boat.Boat = this._entityConfig.level.map.boat;

    if (keysDown["ArrowLeft"]) boatEntity.turnBy(-Math.PI / 2);
    else if (keysDown["ArrowRight"]) boatEntity.turnBy(Math.PI / 2);
  }
}
