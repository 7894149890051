import * as PIXI from "pixi.js";

import * as entity from "booyah/src/entity";

import * as waste from "./waste";
import * as settings from "./settings";
import * as tutorial from "./tutorial";
import * as inputs from "./inputs";
import * as tools from "./tools";
import * as map from "./map";
import * as ui from "./ui";
import * as database from "./database";

export class Level extends entity.CompositeEntity {
  private _container: PIXI.Container;
  private _topContainer: PIXI.Container;
  private _inputHandler: inputs.InputHandler;
  private _map: map.Map;
  private _ui: ui.UI;
  private _score: number;
  private _life: number;
  private _level: number;
  private _wasteCount: Record<waste.WasteName, number>;
  private _elapsedTime: number;

  protected _setup(): void {
    tools.music(this, "theme");

    this._wasteCount = {
      baril: 0,
      can: 0,
      bottle: 0,
      plastic_bag: 0,
    };

    this._score = 0;
    this._life = 3;
    this._level = 1;
    this._elapsedTime = 0;

    this._container = new PIXI.Container();
    this._topContainer = new PIXI.Container();
    this._entityConfig.container.addChild(this._container, this._topContainer);

    // SEA background
    this._container.addChild(
      tools.rect({
        color: tools.blueSea,
        width: tools.screenSize.x,
        height: tools.screenSize.y,
      })
    );

    this._inputHandler = new inputs.InputHandler();
    this._activateChildEntity(
      this._inputHandler,
      entity.extendConfig({ container: this._topContainer, level: this })
    );

    this._map = new map.Map();
    this._activateChildEntity(
      this._map,
      entity.extendConfig({
        container: this._container,
        level: this,
      })
    );

    this._ui = new ui.UI();
    this._activateChildEntity(
      this._ui,
      entity.extendConfig({ container: this._topContainer, level: this })
    );

    this._on(this._map.boat.net, "collected", this._onCollected);
    this._on(this._map.boat.net, "released", this._onNetReleased);
    this._on(this._map.boat.net, "isFull", this._onNetIsFull);
    this._on(this._map.boat, "crash", this._onBoatCrash);
    this._on(this, "gameOver", this._onGameOver);

    // Setup tutorial
    this._activateChildEntity(
      new tutorial.TutorialSequence(),
      entity.extendConfig({ container: this._topContainer, level: this })
    );

    database.logEvent("level_start");

    database
      .getLeaderboard()
      .then((records) => console.log("Got records", records));
  }

  protected _update(): void {
    this._elapsedTime += this._lastFrameInfo.timeSinceLastFrame;
  }

  protected _teardown(): void {
    this._entityConfig.container.removeChild(
      this._container,
      this._topContainer
    );
  }

  get map(): map.Map {
    return this._map;
  }

  get score(): number {
    return this._score;
  }

  private _onCollected(trash: waste.Waste): void {
    const addedScore = waste.wasteDefs[trash.name].score;
    const posInMapContainer = tools.toPixiPoint(trash.position);
    const posOnScreen = this._map.container.toGlobal(posInMapContainer);

    this._wasteCount[trash.name]++;

    this._score += addedScore;

    this._displayScoreAnimation(addedScore, posOnScreen);

    tools.fx(this, "points");

    this.emit("change:score", this._score);
  }

  private _onNetReleased(collected: waste.WasteInNet): void {
    const points = waste.wasteDefs[collected.name].score;
    this._score -= points;

    this.emit("change:score", this._score);
  }

  private _onNetIsFull() {
    // Update score
    this._score += 250;

    this._displayScoreAnimation(250);

    this.emit("change:score", this._score);

    // Level up difficulty
    this._map.boat.levelUp();
    this._map.boat.net.levelUp();
    this._level++;

    // Replenish wastes if needed
    this._map.regenerateWastes(map.initialWasteCountPerTile * 0.75);

    database.logEvent("level_up", { level: this._level });
  }

  private _onBoatCrash() {
    if (settings.inDebugMode()) return;

    this._life -= 1;

    if (this._life <= 0) {
      this.emit("gameOver");
      database.logEvent("level_end");
    } else {
      this.emit("change:life", this._life);
      database.logEvent("lost_life", { life: this._life });
    }
  }

  private _onGameOver() {
    if (this._map.boat.state === "dead") return;

    this._map.boat.kill();

    this._transition = entity.makeTransition("gameOver", {
      score: this._score,
      wastes: tools.clone(this._wasteCount),
      time: this._elapsedTime,
    });
  }

  /** If position is not provided, will use screen center */
  private _displayScoreAnimation(
    score: 25 | 50 | 75 | 100 | 250,
    position?: PIXI.Point
  ): void {
    this._activateChildEntity(
      tools.animatedSprite(
        this,
        `score_${score}`,
        {
          transitionOnComplete: true,
        },
        (it) => {
          it.sprite.loop = false;
          it.sprite.animationSpeed = 25 / 60;
          it.sprite.anchor.set(0.5, 0.5);
          it.sprite.position = position ?? tools.screenCenter;
        }
      ),
      entity.extendConfig({
        container: this._topContainer,
      })
    );
  }
}
