import * as firebaseApp from "firebase/app";
import * as firestore from "firebase/firestore";
import * as firebaseAnalytics from "firebase/analytics";

import * as settings from "./settings";

const firebaseConfig = {
  apiKey: "AIzaSyAmM2O2qIokUHuCUggW5i3YeiH7roEezFE",
  authDomain: "swiog-polycycle.firebaseapp.com",
  projectId: "swiog-polycycle",
  storageBucket: "swiog-polycycle.appspot.com",
  messagingSenderId: "200339804264",
  appId: "1:200339804264:web:e55a6eedf8a27284e72bdd",
  measurementId: "G-8YH418JTQQ",
};

let app: firebaseApp.FirebaseApp;
let db: firestore.Firestore;
let analytics: firebaseAnalytics.Analytics;

if (!settings.inDebugMode()) {
  // Initialize Firebase
  app = firebaseApp.initializeApp(firebaseConfig, {
    automaticDataCollectionEnabled: false,
  });
  db = firestore.getFirestore(app);
  analytics = firebaseAnalytics.getAnalytics(app);
}

export async function fetchGlobalScore(): Promise<number> {
  if (settings.inDebugMode()) return 100000;

  try {
    const docRef = await firestore.getDoc(
      firestore.doc(db, "global", "totals")
    );
    return docRef.data().score;
  } catch (e) {
    console.error("Error getting global score: ", e);
    throw e;
  }
}

/**
 * Returns the place in the leaderboard
 * @param score
 * @param time
 * @param name
 * @returns
 */
export async function addScore(
  score: number,
  time: number,
  name: string
): Promise<number> {
  if (settings.inDebugMode()) return;
  if (isNaN(score)) return;

  // Add the score
  try {
    const docRef = await firestore.addDoc(firestore.collection(db, "session"), {
      score,
      time,
      name,
      language: settings.getLanguage(),
      created: firestore.serverTimestamp(),
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }

  // Get the place in the leaderboard
  let place;
  try {
    const query = await firestore.query(
      firestore.collection(db, "session"),
      firestore.where("score", ">", score)
    );
    const snapshot = await firestore.getCountFromServer(query);
    place = snapshot.data().count + 1;
    console.log("count: ", place);
  } catch (e) {
    console.error("Error getting place in leaderboard: ", e);
  }

  // Increment total values
  // Continue even if this part fails
  firestore
    .updateDoc(firestore.doc(db, "global", "totals"), {
      sessions: firestore.increment(1),
      score: firestore.increment(score),
      time: firestore.increment(time),
    })
    .then(() => console.log("Global totals updated"))
    .catch((e) => console.log("Error updating global score", e));

  return place;
}

export type EventType = "level_start" | "level_end" | "level_up" | "lost_life";

export function logEvent(type: EventType, data?: Record<string, unknown>) {
  if (settings.inDebugMode()) return;
  if (!analytics) return;

  try {
    firebaseAnalytics.logEvent(analytics, type, data);
  } catch (e) {
    console.error("Error logging event: ", e);
  }
}

export interface LeaderboardRecord {
  name: string;
  score: number;
}

export async function getLeaderboard(): Promise<LeaderboardRecord[]> {
  const docs = await firestore.getDocs(
    firestore.query(
      firestore.collection(db, "session"),
      firestore.orderBy("score", "desc"),
      firestore.limit(10)
    )
  );
  // We know that is the correct type
  return docs.docs.map((snapshot) =>
    snapshot.data()
  ) as unknown as LeaderboardRecord[];
}
