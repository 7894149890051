import * as _ from "underscore";
import * as planck from "planck";
import * as PIXI from "pixi.js";

import * as entity from "booyah/src/entity";

import * as settings from "./settings";
import * as assets from "./assets";
import * as tools from "./tools";
import * as map from "./map";

/** Definition of a static iceberg within a tile  */
export interface IcebergDef {
  /** Position as percentage within the tile */
  position: planck.Vec2;
  type: IcebergType;
}

export const icebergTypes = [
  "bean",
  "big",
  "medium",
  "small",
  "boomerang",
] as const;

export type IcebergType = typeof icebergTypes[number];

export interface IcebergElement {
  name: assets.SpriteName | assets.SpriteSheetName;
  position: PIXI.IPointData;
}

export type IcebergSettings = {
  elements: IcebergElement[][];
} & (
  | {
      polygon: planck.Vec2[];
    }
  | {
      radius: number;
    }
);

export const icebergSettings: Record<IcebergType, IcebergSettings> = {
  bean: {
    radius: tools.toMeters(45),
    elements: [
      [],
      [
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: 0, y: 0 },
        },
      ],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -10, y: -10 },
        },
      ],
    ],
  },
  boomerang: {
    polygon: [
      planck.Vec2(tools.toMeters(-75), tools.toMeters(-160)),
      planck.Vec2(tools.toMeters(75), tools.toMeters(-75)),
      planck.Vec2(tools.toMeters(110), tools.toMeters(50)),
      planck.Vec2(tools.toMeters(-75), tools.toMeters(135)),
      planck.Vec2(tools.toMeters(-150), tools.toMeters(75)),
      planck.Vec2(tools.toMeters(-75), tools.toMeters(-75)),
    ],
    elements: [
      [],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -20, y: -10 },
        },
        {
          name: "on_iceberg_penguin_stay_SE",
          position: { x: 20, y: 18 },
        },
        {
          name: "on_iceberg_transat_left",
          position: { x: 0, y: 10 },
        },
      ],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -20, y: -35 },
        },
        {
          name: "on_iceberg_penguin_stay_E",
          position: { x: 20, y: 10 },
        },
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: -25, y: 30 },
        },
        {
          name: "on_iceberg_penguin_walk_reversed",
          position: { x: 10, y: -20 },
        },
      ],
      [
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: -25, y: 30 },
        },
        {
          name: "on_iceberg_palm",
          position: { x: 10, y: -10 },
        },
      ],
    ],
  },
  big: {
    polygon: [
      planck.Vec2(tools.toMeters(-130), tools.toMeters(-130)),
      planck.Vec2(tools.toMeters(-115), tools.toMeters(-50)),
      planck.Vec2(tools.toMeters(-160), tools.toMeters(40)),
      planck.Vec2(tools.toMeters(-130), tools.toMeters(150)),
      planck.Vec2(tools.toMeters(120), tools.toMeters(150)),
      planck.Vec2(tools.toMeters(150), tools.toMeters(120)),
      planck.Vec2(tools.toMeters(100), tools.toMeters(0)),
      planck.Vec2(tools.toMeters(120), tools.toMeters(-130)),
      planck.Vec2(tools.toMeters(80), tools.toMeters(-165)),
    ],
    elements: [
      [],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -40, y: -40 },
        },
        {
          name: "on_iceberg_penguin_walk_reversed",
          position: { x: 20, y: -25 },
        },
        {
          name: "on_iceberg_transat_left",
          position: { x: -25, y: 10 },
        },
        {
          name: "on_iceberg_penguin_stay_NE",
          position: { x: 20, y: 20 },
        },
        {
          name: "on_iceberg_transat_right",
          position: { x: 30, y: 15 },
        },
        {
          name: "on_iceberg_penguin_stay_W",
          position: { x: -35, y: 25 },
        },
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: -10, y: 40 },
        },
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: 30, y: 45 },
        },
      ],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -40, y: -40 },
        },
        {
          name: "on_iceberg_penguin_stay_NE",
          position: { x: 40, y: -40 },
        },
        {
          name: "on_iceberg_igloo_left",
          position: { x: 0, y: 0 },
        },
        {
          name: "on_iceberg_penguin_stay_NE",
          position: { x: 40, y: 15 },
        },
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: -10, y: 40 },
        },
        {
          name: "on_iceberg_transat_left",
          position: { x: 30, y: 35 },
        },
      ],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -40, y: -40 },
        },
        {
          name: "on_iceberg_penguin_stay_NE",
          position: { x: 40, y: -40 },
        },
        {
          name: "on_iceberg_penguin_walk_reversed",
          position: { x: -25, y: 20 },
        },
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: -10, y: 40 },
        },
        {
          name: "on_iceberg_penguin_stay_E",
          position: { x: 40, y: 30 },
        },
      ],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -40, y: -40 },
        },
        {
          name: "on_iceberg_palm",
          position: { x: 20, y: 0 },
        },
        {
          name: "on_iceberg_transat_left",
          position: { x: -30, y: 35 },
        },
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: -10, y: 40 },
        },
      ],
      [
        {
          name: "on_iceberg_penguin_stay_NW",
          position: { x: -40, y: -40 },
        },
        {
          name: "on_iceberg_penguin_stay_NE",
          position: { x: 40, y: -40 },
        },
        {
          name: "on_iceberg_penguin_stay_S",
          position: { x: -10, y: 40 },
        },
        {
          name: "on_iceberg_igloo_right",
          position: { x: 0, y: 0 },
        },
      ],
    ],
  },
  medium: {
    // 190 * 170
    polygon: [
      planck.Vec2(tools.toMeters(-55), tools.toMeters(-60)),
      planck.Vec2(0, tools.toMeters(-80)),
      planck.Vec2(tools.toMeters(80), tools.toMeters(-60)),
      planck.Vec2(tools.toMeters(20), tools.toMeters(60)),
    ],
    elements: [[]],
  },
  small: {
    radius: tools.toMeters(20),
    elements: [[]],
  },
};

for (const icebergName in icebergSettings) {
  const iceberg = icebergSettings[icebergName as keyof typeof icebergSettings];
  for (const elements of iceberg.elements) {
    for (const element of elements) {
      element.position.x *= 3;
      element.position.y *= 3;
    }
  }
}

export class Iceberg extends entity.CompositeEntity {
  private _position: planck.Vec2;
  private _container: PIXI.Container;
  private _body: planck.Body;

  constructor(position: planck.Vec2, private _type: IcebergType) {
    super();

    this._position = position;
  }

  protected _setup(): void {
    const options = icebergSettings[this._type];

    // Setup physics
    const userData: map.BodyUserData = {
      type: "iceberg",
      entity: this,
    };
    this._body = this._entityConfig.world.createBody({
      position: this._position,
      userData,
    });
    this._body.createFixture({
      shape:
        "radius" in options
          ? new planck.Circle(options.radius)
          : new planck.Polygon(options.polygon),
      density: 0,
      restitution: 1,
      filterCategoryBits: map.BodyCategoryBits.iceberg,
    });

    // Setup graphics
    this._container = new PIXI.Container();
    this._container.position = tools.toPixiPoint(this._position);
    this._entityConfig.container.addChild(this._container);

    // Iceberg sprite
    this._activateChildEntity(
      tools.animatedSprite(this, `iceberg_${this._type}`, {}, (it) => {
        it.sprite.loop = true;
        it.sprite.animationSpeed = 25 / 60;
        it.sprite.anchor.set(0.5);
        it.sprite.gotoAndPlay(_.random(it.sprite.totalFrames - 1));

        // Elements inside
        const elements = tools.random(icebergSettings[this._type].elements);

        for (const element of elements) {
          if (tools.isSpriteName(element.name)) {
            it.sprite.addChild(
              tools.sprite(this, element.name, (it) => {
                it.anchor.set(0.5);
                it.position.copyFrom(element.position);
              })
            );
          } else {
            this._activateChildEntity(
              tools.animatedSprite(this, element.name, {}, (it) => {
                it.sprite.loop = true;
                it.sprite.animationSpeed = 25 / 60;
                it.sprite.anchor.set(0.5);
                it.sprite.gotoAndPlay(_.random(it.sprite.totalFrames - 1));
                it.sprite.position.copyFrom(element.position);
              }),
              entity.extendConfig({
                container: it.sprite,
              })
            );
          }
        }
      }),
      entity.extendConfig({
        container: this._container,
      })
    );

    if (settings.inDebugMode()) {
      if ("radius" in options) {
        this._container.addChild(
          new PIXI.Graphics()
            .beginFill(0x0000ff, 0.5)
            .drawCircle(0, 0, tools.toPixels(options.radius))
            .endFill()
        );
      } else {
        this._container.addChild(
          new PIXI.Graphics()
            .beginFill(0x0000ff, 0.5)
            .drawPolygon(options.polygon.map((it) => tools.toPixiPoint(it)))
            .endFill()
        );
      }
    }
  }

  protected _teardown(): void {
    this._entityConfig.world.destroyBody(this._body);
    delete this._body;

    this._entityConfig.container.removeChild(this._container);
    delete this._container;
  }
}
