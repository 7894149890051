import * as PIXI from "pixi.js";

import * as entity from "booyah/src/entity";
import * as tween from "booyah/src/tween";
import * as easing from "booyah/src/easing";

import * as tools from "./tools";
import * as waste from "./waste";

export class UI extends entity.CompositeEntity {
  private _container: PIXI.Container;
  private _lifeSprites: PIXI.Sprite[];
  private _scoreBackground: PIXI.Sprite;

  protected _setup() {
    this._container = new PIXI.Container();
    this._entityConfig.container.addChild(this._container);

    this._on(
      this._entityConfig.level.map.boat.net,
      "isFull",
      this._onNetIsFull
    );

    {
      const getBarX = (score: number) => {
        return score.toString().length * 50 - 400;
      };

      this._scoreBackground = this._container.addChild(
        tools.sprite(this, "red_bar", (it) => {
          it.anchor.set(0.5);
          it.position.set(getBarX(0), 50);
          it.scale.set(0.7);
        })
      );

      const text = this._container.addChild(
        tools.text(
          " 0 ",
          {
            fill: "white",
            fontFamily: "Nexa Heavy",
            fontSize: 72,
          },
          (it) => {
            it.anchor.set(0);
            it.position.set(0, 10);
          }
        )
      );

      this._on(this._entityConfig.level, "change:score", (score: number) => {
        text.text = ` ${score.toLocaleString()} `;
        this._scoreBackground.position.x = getBarX(score);
      });
    }

    {
      this._container.addChild(
        tools.rect(
          {
            color: 0xffffff,
            width: 220,
            height: 60,
          },
          (it) => it.position.set(0, 100)
        ),
        tools.circle(
          {
            color: 0xffffff,
            radius: 30,
          },
          (it) => it.position.set(220, 130)
        )
      );

      this._lifeSprites = [];

      for (let i = 0; i < 3; i++) {
        this._lifeSprites.push(
          this._container.addChild(
            tools.sprite(this, "life", (it) =>
              it.position.set(10 + i * 80, 107)
            )
          )
        );
      }

      this._on(this._entityConfig.level, "change:life", (life: number) => {
        for (let i = 0; i < 3; i++) {
          if (i < life) {
            this._lifeSprites[i].tint = 0xffffff;
            this._lifeSprites[i].alpha = 1;
          } else {
            this._lifeSprites[i].tint = 0x000000;
            this._lifeSprites[i].alpha = 0.25;
          }
        }
      });
    }
  }

  protected _teardown(): void {
    this._entityConfig.container.removeChild(this._container);
  }

  private _onNetIsFull(wastes: waste.WasteInNet[]) {
    const from = this._entityConfig.level.map.boat.net.screenPosition;
    const to = new PIXI.Point(250, 50);
    const tweenDuration = 1000;
    const interval = 100;

    const container = new PIXI.Container();
    this._container.addChild(container);

    // Create each animation separately, with a pause before it
    const animations = wastes.map((waste, index) => {
      const sprite = tools.sprite(this, waste.name);
      sprite.anchor.set(0.5);

      return new entity.EntitySequence([
        new entity.WaitingEntity(index * interval),
        new entity.FunctionCallEntity(() => {
          container.addChild(sprite);
        }),
        new tween.Tween({
          obj: sprite,
          property: "position",
          from,
          to,
          duration: tweenDuration,
          easing: easing.easeOutQuad,
          interpolate: tween.interpolation.point,
        }),
        new entity.FunctionCallEntity(() => {
          container.removeChild(sprite);
        }),
      ]);
    });

    this._activateChildEntity(
      tools.animatedSprite(
        this,
        "score_background",
        { transitionOnComplete: true },
        (it) => {
          it.sprite.animationSpeed = 25 / 60;
          it.sprite.loop = false;
          it.sprite.anchor.set(1, 0.5);
          it.sprite.scale.set(1.25);
          it.sprite.position.y = -2;
          it.sprite.position.x =
            this._scoreBackground.x + this._scoreBackground.width + 70;
        }
      ),
      entity.extendConfig({ container: this._scoreBackground })
    );

    this._activateChildEntity(
      new entity.EntitySequence([
        new entity.FunctionCallEntity(() => tools.playFxLoop(this, "bonus")),
        new entity.ParallelEntity(animations),
        new entity.FunctionCallEntity(() => {
          this._container.removeChild(container);
        }),
        new entity.FunctionCallEntity(() => tools.stopFx(this, "bonus")),
      ])
    );
  }
}
