{
  "settings": {
    "id": "settings",
    "fr": "Paramètres",
    "en": "Settings",
    "es": "Configuraciones",
    "pt": "Configuração"
  },
  "game_over": {
    "id": "game_over",
    "fr": "Fin de la partie",
    "en": "Game over",
    "es": "Game over",
    "pt": "Game over"
  },
  "recycled_wastes": {
    "id": "recycled_wastes",
    "fr": "Félicitations, vous avez recyclé tous ces déchets !",
    "en": "Congratulations, you managed to recycle all this waste!",
    "es": "Enhorabuena, ¡has conseguido reciclar todos estos residuos!",
    "pt": "Parabéns, você conseguiu reciclar todo esse lixo!"
  },
  "total": {
    "id": "total",
    "fr": "Total :",
    "en": "Total:",
    "es": "Total:",
    "pt": "Total:"
  },
  "total_points": {
    "id": "total_points",
    "fr": "Total des points cumulés avec les autres joueurs :",
    "en": "Total points accumulated with other players:",
    "es": "Total de puntos acumulados con otros jugadores:",
    "pt": "Total dos pontos acumulados com outros jogadores:"
  },
  "try_again": {
    "id": "try_again",
    "fr": "Rejouer",
    "en": "Try again",
    "es": "Volver a intentarlo",
    "pt": "Tente novamente"
  },
  "rules": {
    "id": "rules",
    "fr": "Règles du jeu",
    "en": "Rules",
    "es": "Reglas",
    "pt": "Regras"
  },
  "legal": {
    "id": "legal",
    "fr": "Légal",
    "en": "Legal",
    "es": "Legal",
    "pt": "Jurídico"
  },
  "no": {
    "id": "no",
    "fr": "Non",
    "en": "No",
    "es": "No",
    "pt": "Não"
  },
  "yes": {
    "id": "yes",
    "fr": "Oui",
    "en": "Yes",
    "es": "Sí",
    "pt": "Sim"
  },
  "MadeWithLov": {
    "id": "MadeWithLov",
    "fr": "Créé avec amour par PlayCurious",
    "en": "Made with love by PlayCurious",
    "es": "Hecho con amor por PlayCurious",
    "pt": "Feito com amor por PlayCurious"
  },
  "MusicBy": {
    "id": "MusicBy",
    "fr": "Musique de",
    "en": "Music by",
    "es": "Música de",
    "pt": "Música de"
  },
  "SoundDesign": {
    "id": "SoundDesign",
    "fr": "Design sonore de",
    "en": "Sound design by",
    "es": "Diseño de sonido de",
    "pt": "Designer de som"
  },
  "play": {
    "id": "play",
    "fr": "Jouer",
    "en": "Play",
    "es": "Jugar",
    "pt": "Jogar"
  },
  "tuto_control_joystick": {
    "id": "tuto_control_joystick",
    "fr": "<b>Tutoriel :</b> Pour vous déplacer, faites glisser votre doigt dans la direction souhaitée.",
    "en": "<b>Tutorial:</b> Drag your finger in the direction you want to move.",
    "es": "<b>Tutorial:</b> Arrastra el dedo en la dirección en la que te quieras mover.",
    "pt": "<b>Tutorial:</b> Arraste o dedo na direção em que deseja seguir."
  },
  "tuto_control_keyboard": {
    "id": "tuto_control_keyboard",
    "fr": "<b>Tutoriel :</b> Vous pouvez vous servir des flèches de votre clavier si vous utilisez un ordinateur.",
    "en": "<b>Tutorial:</b> Or the arrows on your keyboard if you use a computer.",
    "es": "<b>Tutorial:</b> O las flechas de tu teclado si utilizas un ordenador.",
    "pt": "<b>Tutorial:</b> Ou use as setas do teclado se estiver jogando no computador."
  },
  "tuto_waste": {
    "id": "tuto_waste",
    "fr": "<b>Tutoriel :</b> Passez sur un déchet pour le mettre dans le filet du bateau.",
    "en": "<b>Tutorial:</b> Pass over a piece of trash to collect it in the ship's net.",
    "es": "<b>Tutorial:</b> Pasa sobre la basura para recogerla con la red del barco.",
    "pt": "<b>Tutorial:</b> Passe sobre um detrito para retirá-lo com a rede do barco."
  },
  "tuto_bonus": {
    "id": "tuto_bonus",
    "fr": "<b>Tutoriel :</b> Remplissez votre filet et gagnez un bonus !",
    "en": "<b>Tutorial:</b> Fill your net to earn a bonus!",
    "es": "<b>Tutorial:</b> ¡Llena la red para ganar un bonus!",
    "pt": "<b>Tutorial:</b> Encha a rede para ganhar pontos a mais!"
  },
  "tuto_obstacle": {
    "id": "tuto_obstacle",
    "fr": "<b>Tutoriel :</b> Vous perdez une vie lorsque vous heurtez un obstacle.",
    "en": "<b>Tutorial:</b> If you hit an obstacle you lose a life.",
    "es": "<b>Tutorial:</b> Si chocas con un obstáculo pierdes una vida.",
    "pt": "<b>Tutorial:</b> Se esbarrar num obstáculo, você perde uma vida."
  },
  "tuto_lives": {
    "id": "tuto_lives",
    "fr": "Vous disposez de 3 vies au début du jeu.",
    "en": "You have 3 lives to start",
    "es": "Tienes 3 vidas para empezar",
    "pt": "Para começar, você tem três vidas"
  }
}