import * as PIXI from "pixi.js";
import * as planck from "planck";
import * as _ from "underscore";

import * as entity from "booyah/src/entity";
import * as util from "booyah/src/util";

import FormattedText from "pixi-multistyle-text";

import * as assets from "./assets";
import * as settings from "./settings";

import translations from "../json/translations.json";

export const screenSize = new PIXI.Point(1920, 1080);
export const screenCenter = new PIXI.Point(screenSize.x / 2, screenSize.y / 2);
export const blueSea = 0x40afc2;
export const red = 0xff1212;

export class WaitingEntityOptions {
  onSetup?: (accumulatedTime: number) => void;
  onUpdate?: (accumulatedTime: number) => void;
  onTeardown?: (accumulatedTime: number) => void;
}

/** Waits until time is up, then requests transition
 * Optionally calls a function with the elapsed time on each frame
 */
export class WaitingEntity extends entity.EntityBase {
  private _accumulatedTime: number;
  private _options: WaitingEntityOptions;

  /** @wait is in milliseconds */
  constructor(public readonly wait: number, options?: WaitingEntityOptions) {
    super();

    this._options = util.fillInOptions(options, new WaitingEntityOptions());
  }

  _setup() {
    this._accumulatedTime = 0;
    if (this._options.onSetup) this._options.onSetup(this._accumulatedTime);
  }

  _update(frameInfo: entity.FrameInfo) {
    this._accumulatedTime += frameInfo.timeSinceLastFrame;
    if (this._options.onUpdate) this._options.onUpdate(this._accumulatedTime);

    if (this._accumulatedTime >= this.wait) {
      this._transition = entity.makeTransition();
    }
  }

  protected _teardown(): void {
    if (this._options.onTeardown)
      this._options.onTeardown(this._accumulatedTime);
  }
}

/**
 * A more logical version of the AnimatedSpriteEntity from Booyah
 */
export class AnimatedSpriteEntityOptions {
  loop = false;

  animationSpeed?: number;
  position?: PIXI.IPoint;
  anchor?: PIXI.IPoint;
  rotation?: number;
  startingFrame?: number;
}

export class AnimatedSpriteEntity extends entity.EntityBase {
  private _spritesheetName: string;
  private _options: AnimatedSpriteEntityOptions;

  private _sprite: PIXI.AnimatedSprite;

  constructor(
    spritesheetName: string,
    options?: Partial<AnimatedSpriteEntityOptions>
  ) {
    super();

    this._spritesheetName = spritesheetName;
    this._options = util.fillInOptions(
      options,
      new AnimatedSpriteEntityOptions()
    );
  }

  _setup() {
    const resource =
      this._entityConfig.app.loader.resources[this._spritesheetName];
    if (!resource)
      throw new Error(
        `Cannot find resource for spritesheet: ${this._spritesheetName}`
      );

    this._sprite = new PIXI.AnimatedSprite(
      Object.values(resource.textures) as PIXI.Texture[],
      false
    );
    this._entityConfig.container.addChild(this._sprite);

    if (!this._options.loop) {
      // PIXI.AnimatedSprite loops by default
      this._sprite.loop = false;
      this._sprite.onComplete = this._onAnimationComplete.bind(this);
    }

    for (const prop of ["animationSpeed", "position", "anchor", "rotation"]) {
      // @ts-ignore
      if (_.has(this._options, prop)) this._sprite[prop] = this._options[prop];
    }

    if (typeof this._options.startingFrame !== "undefined") {
      this._sprite.gotoAndPlay(this._options.startingFrame);
    } else {
      this._sprite.play();
    }
  }

  _update(frameInfo: entity.FrameInfo) {
    this._sprite.update(frameInfo.timeScale);
  }

  onSignal(frameInfo: entity.FrameInfo, signal: string) {
    switch (signal) {
      case "pause":
        this._sprite.stop();
        break;
      case "play":
        this._sprite.play();
        break;
    }
  }

  _teardown() {
    this._entityConfig.container.removeChild(this._sprite);
    delete this._sprite;
  }

  private _onAnimationComplete() {
    this._transition = entity.makeTransition();
  }
}

export const antiAliasing = 100;

export interface RectOptions {
  width: number;
  height: number;
  color?: number;
  alpha?: number;
}

/**
 * Make a rectangle
 */
export function rect(
  options: RectOptions,
  mod?: (it: PIXI.Graphics, center: () => void) => unknown
) {
  const rect = new PIXI.Graphics()
    .beginFill(options.color, options.alpha)
    .drawRect(0, 0, options.width, options.height)
    .endFill();

  mod?.(rect, () => {
    rect.position.set(-rect.width / 2, -rect.height / 2);
  });

  return rect;
}

export interface CircleOptions {
  radius: number;
  color?: number;
  alpha?: number;
}

/**
 * Make a circle
 */
export function circle(
  options: CircleOptions,
  mod?: (it: PIXI.Graphics) => unknown
) {
  const circle = new PIXI.Graphics()
    .beginFill(options.color, options.alpha)
    .drawCircle(0, 0, options.radius * antiAliasing)
    .endFill();

  circle.scale.set(1 / antiAliasing);

  mod?.(circle);

  return circle;
}

export interface EllipseOptions {
  width: number;
  height: number;
  color?: number;
  alpha?: number;
}

/**
 * Make an ellipse
 */
export function ellipse(
  options: EllipseOptions,
  mod?: (it: PIXI.Graphics) => unknown
) {
  const ellipse = new PIXI.Graphics()
    .beginFill(options.color, options.alpha)
    .drawEllipse(
      0,
      0,
      options.width * antiAliasing,
      options.height * antiAliasing
    )
    .endFill();

  ellipse.scale.set(1 / antiAliasing);

  mod?.(ellipse);

  return ellipse;
}

export interface LineOptions {
  weight?: number;
  color?: number;
  alpha?: number;
  from?: PIXI.IPointData;
  to: PIXI.IPointData;
}

export function line(
  options: LineOptions,
  mod?: (it: PIXI.Graphics) => unknown
) {
  const line = new PIXI.Graphics()
    .lineStyle(options.weight ?? 1, options.color, options.alpha)
    .moveTo(options.from?.x ?? 0, options.from?.y ?? 0)
    .lineTo(options.to.x, options.to.y);

  mod?.(line);

  return line;
}

/**
 * Make a sprite
 */
export function sprite(
  ctx: entity.EntityBase,
  name: assets.SpriteName,
  modifier?: (it: PIXI.Sprite) => unknown
): PIXI.Sprite {
  const path = assets.images[name].pathname;

  if (!ctx.entityConfig.app.loader.resources[path])
    throw new Error(`${name} sprite not found`);

  const sprite = new PIXI.Sprite(
    ctx.entityConfig.app.loader.resources[path].texture
  );

  modifier?.(sprite);

  return sprite;
}

/**
 * Make a non-animated sprite from spritesheet
 */
export function freeSprite(
  ctx: entity.EntityBase,
  name: assets.SpriteSheetName,
  modifier?: (it: FreeSprite) => unknown
) {
  const path = assets.spritesheets[name].pathname;

  if (!ctx.entityConfig.app.loader.resources[path])
    throw new Error(`${name} sprite not found`);

  const sprite = new FreeSprite(
    Object.values(ctx.entityConfig.app.loader.resources[path].textures)
  );

  modifier?.(sprite);

  return sprite;
}

/**
 * Make a sprite who can be toggled from boolean
 */
export function booleanSprite(
  ctx: entity.EntityBase,
  names: [on: assets.SpriteName, off: assets.SpriteName],
  modifier?: (it: BooleanSprite) => unknown
) {
  const [pathOn, pathOff] = names.map((name) => assets.images[name].pathname);

  if (!ctx.entityConfig.app.loader.resources[pathOn])
    throw new Error(`${names[0]} sprite not found`);

  if (!ctx.entityConfig.app.loader.resources[pathOff])
    throw new Error(`${names[1]} sprite not found`);

  const sprite = new BooleanSprite(
    ctx.entityConfig.app.loader.resources[pathOn].texture,
    ctx.entityConfig.app.loader.resources[pathOff].texture
  );

  modifier?.(sprite);

  return sprite;
}

/**
 * Make a rounded sprite
 */
export function roundedSprite(
  ctx: entity.EntityBase,
  name: assets.SpriteName & `${string}_${number}_${string}`,
  sideSizes:
    | number
    | {
        leftWidth?: number;
        topHeight?: number;
        rightWidth?: number;
        bottomHeight?: number;
      },
  modifier?: (it: PIXI.NineSlicePlane, center: () => void) => unknown
): PIXI.NineSlicePlane {
  const path = assets.images[name].pathname;

  if (!ctx.entityConfig.app.loader.resources[path])
    throw new Error(`${name} sprite not found`);

  const sprite = new PIXI.NineSlicePlane(
    ctx.entityConfig.app.loader.resources[path].texture,
    typeof sideSizes === "number" ? sideSizes : sideSizes.leftWidth,
    typeof sideSizes === "number" ? sideSizes : sideSizes.topHeight,
    typeof sideSizes === "number" ? sideSizes : sideSizes.rightWidth,
    typeof sideSizes === "number" ? sideSizes : sideSizes.bottomHeight
  );

  modifier?.(sprite, () => {
    sprite.position.set(
      -(sprite.width * sprite.scale.x) / 2,
      -(sprite.height * sprite.scale.y) / 2
    );
  });

  return sprite;
}

/**
 * Make an animated sprite
 */
export function animatedSprite(
  ctx: entity.EntityBase,
  name: assets.SpriteSheetName,
  options?: { resetFrame?: boolean; transitionOnComplete?: boolean },
  modifier?: (it: entity.AnimatedSpriteEntity) => unknown
): entity.AnimatedSpriteEntity {
  const path = assets.spritesheets[name].pathname;

  const e = util.makeAnimatedSprite(
    ctx.entityConfig.app.loader.resources[path],
    options
  );

  modifier?.(e);

  return e;
}

export function text(
  msg: string,
  style?: Partial<PIXI.ITextStyle>,
  modifier?: (it: PIXI.Text) => unknown
) {
  const txt = new PIXI.Text(msg, {
    fontFamily: assets.Fonts.NexaHeavy,
    ...(style ?? {}),
  });

  txt.anchor.set(0.5);

  modifier?.(txt);

  return txt;
}

export function formattedText(
  msg: string,
  style?: Partial<PIXI.ITextStyle>,
  modifier?: (it: FormattedText) => unknown
) {
  const txt = new FormattedText(msg, {
    default: {
      fontFamily: assets.Fonts.Nexa,
      ...(style ?? {}),
    },
    i: {
      fontStyle: "italic",
    },
    b: {
      fontFamily: assets.Fonts.NexaHeavy,
    },
    bi: {
      fontFamily: assets.Fonts.NexaHeavy,
      fontStyle: "italic",
    },
    u: {
      fill: 0xfc037f,
    },
  });

  txt.anchor.set(0.5);

  modifier?.(txt);

  return txt;
}

/**
 *  random pick in array or duple
 */
export function random<T>(list: T[] | readonly T[]): T;
/**
 * random between 0 and 1
 */
export function random(): number;
/**
 *  random between 0 and X
 */
export function random(max: number): number;
/**
 *  random in range (including mode)
 */
export function random(min: number, max: number): number;
/**
 *  polyvalent random util
 */
export function random<T>(
  min?: T[] | readonly T[] | number,
  max?: number
): number | T {
  const rand = Math.random();
  if (typeof min === "undefined") {
    return rand;
  } else if (typeof max === "undefined") {
    if (min instanceof Array) {
      return min[Math.floor(rand * min.length)];
    } else {
      return rand * min;
    }
  } else {
    if (min > max) {
      const tmp = min as number;
      min = max;
      max = tmp;
    }
    //@ts-ignore
    return rand * (max - min) + min;
  }
}

/**
 * Gives the cross product of two segments from start and stop values
 */
export function proportion(
  n: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number,
  withinBounds = false
): number {
  const output = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (!withinBounds) return output;
  return start2 < stop2
    ? constrain(output, start2, stop2)
    : constrain(output, stop2, start2);
}

/**
 * Gives the constrained value of a number between a min and max
 */
export function constrain(n: number, low: number, high: number): number {
  return Math.max(Math.min(n, high), low);
}

export class FreeSprite extends PIXI.Sprite {
  private _currentTextureIndex = 0;

  get completion() {
    return this._currentTextureIndex / this._textures.length;
  }

  set completion(value: number) {
    this.gotoProportion(value, 0, 1);
  }

  constructor(private _textures: PIXI.Texture[], firstFrame = 0) {
    super(_textures[firstFrame]);
  }

  public next() {
    this._currentTextureIndex =
      (this._currentTextureIndex + 1) % this._textures.length;
    this.texture = this._textures[this._currentTextureIndex];
  }

  public goto(textureIndex: number) {
    this._currentTextureIndex = textureIndex % this._textures.length;
    this.texture = this._textures[this._currentTextureIndex];
  }

  public gotoProportion(value: number, min: number, max: number) {
    this.goto(
      Math.floor(proportion(value, min, max, 0, this._textures.length - 0.01))
    );
  }
}

/** Multiply transforms in logical (reverse) order to build a final Transform matrix  */
export function chainTransforms(
  transforms: planck.Transform[]
): planck.Transform {
  let lastTransform: planck.Transform = _.last(transforms);
  for (let i = transforms.length - 2; i >= 0; i--) {
    lastTransform = planck.Transform.mulXf(lastTransform, transforms[i]);
  }
  return lastTransform;
}

/**
 * Simple sprite who toggle change appearance from boolean
 */
export class BooleanSprite extends PIXI.Sprite {
  constructor(
    private _onTexture: PIXI.Texture,
    private _offTexture: PIXI.Texture,
    private _value = false
  ) {
    super(_value ? _onTexture : _offTexture);
  }

  get value() {
    return this._value;
  }

  set value(value: boolean) {
    if (this._value === value) return;
    this._value = value;
    this.texture = value ? this._onTexture : this._offTexture;
  }
}

export const metersToPixels = 20;

export function toPixels(meters: number): number {
  return meters * metersToPixels;
}

export function toMeters(pixels: number): number {
  return pixels / metersToPixels;
}

export function toPixiPoint(vec: planck.Vec2) {
  return new PIXI.Point(vec.x * metersToPixels, vec.y * metersToPixels);
}

export function toPlanckVec(point: PIXI.Point) {
  return new planck.Vec2(point.x / metersToPixels, point.y / metersToPixels);
}

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function isSpriteName(name: string): name is assets.SpriteName {
  return name in assets.images;
}

export function fx(
  ctx: entity.EntityBase,
  name: assets.FXName,
  volumeScale = 1
) {
  ctx.entityConfig.fxMachine.play(assets.fxTracks[name].pathname, {
    volumeScale,
  });
}

export function music(ctx: entity.EntityBase, name: assets.MusicName) {
  ctx.entityConfig.jukebox.play(assets.musicTracks[name].pathname);
}

export function playFxLoop(
  ctx: entity.EntityBase,
  name: assets.FXName,
  volumeScale = 1
) {
  ctx.entityConfig.fxMachine.play(assets.fxTracks[name].pathname, {
    volumeScale,
    loop: true,
  });
}

export function stopFx(ctx: entity.EntityBase, name: assets.FXName) {
  ctx.entityConfig.fxMachine.stop(assets.fxTracks[name].pathname);
}

export function translate(id: keyof typeof translations): string {
  console.assert(id in translations, `Translation ${id} not found`);

  return translations[id][settings.getLanguage()] ?? translations[id].en;
}
