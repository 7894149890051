import * as PIXI from "pixi.js";
import * as planck from "planck";
import * as _ from "underscore";

import * as entity from "booyah/src/entity";

import * as icebergs from "./iceberg";
import * as settings from "./settings";
import * as animals from "./animal";
import * as tools from "./tools";

export const mapTileSize = tools.toMeters(2000);

interface ShorthandPos {
  x: number;
  y: number;
}

interface ShorthandTile {
  iceberg?: Partial<Record<icebergs.IcebergType, ShorthandPos[]>>;
  animal?: Partial<Record<animals.AnimalType, ShorthandPos[][]>>;
}

/** Shorthand tile design format */
const tileDesign: Record<string, ShorthandTile> = {
  empty: {},
  a: {
    iceberg: {
      big: [{ x: 1321, y: 1289 }],
      bean: [
        { x: 345, y: 1520 },
        { x: 1759, y: 1498 },
        { x: 991, y: 1080 },
      ],
      medium: [{ x: 1680, y: 1100 }],
      small: [
        { x: 195, y: 1485 },
        { x: 328, y: 1400 },
        { x: 996, y: 1310 },
        { x: 1254, y: 995 },
        { x: 1521, y: 1544 },
      ],
    },
    animal: {
      dolphin: [
        [
          { x: 1157, y: 237 },
          { x: 1644, y: 128 },
          { x: 1744, y: 808 },
          { x: 1472, y: 505 },
          { x: 1057, y: 631 },
        ],
      ],
    },
  },
  b: {
    iceberg: {
      bean: [
        { x: 403, y: 482 },
        { x: 1496, y: 1278 },
      ],
      medium: [{ x: 1588, y: 689 }],
      small: [
        { x: 584, y: 427 },
        { x: 1333, y: 1344 },
        { x: 1323, y: 1462 },
        { x: 1450, y: 1424 },
      ],
    },
    animal: {
      whale: [
        [
          { x: 324, y: 696 },
          { x: 1476, y: 610 },
          { x: 1437, y: 1812 },
          { x: 561, y: 1913 },
          { x: 165, y: 1399 },
        ],
      ],
    },
  },
  c: {
    iceberg: {
      big: [{ x: 745, y: 1530 }],
      boomerang: [
        { x: 643, y: 1154 },
        { x: 1332, y: 596 },
      ],
      medium: [
        { x: 275, y: 1311 },
        { x: 1090, y: 524 },
        { x: 1648, y: 452 },
      ],
      bean: [
        { x: 370, y: 1096 },
        { x: 477, y: 1368 },
        { x: 313, y: 1532 },
        { x: 1276, y: 290 },
        { x: 1447, y: 812 },
      ],
      small: [
        { x: 204, y: 1376 },
        { x: 1013, y: 589 },
        { x: 1570, y: 511 },
        { x: 1920, y: 1057 },
      ],
    },
    animal: {
      penguin: [
        [
          { x: 1321, y: 1006 },
          { x: 1440, y: 840 },
          { x: 1310, y: 578 },
          { x: 950, y: 690 },
          { x: 1200, y: 944 },
        ],
      ],
    },
  },
  d: {
    iceberg: {
      big: [{ x: 525, y: 1035 }],
      boomerang: [
        { x: 550, y: 550 },
        { x: 1500, y: 1338 },
      ],
      medium: [
        { x: 330, y: 530 },
        { x: 1550, y: 1610 },
        { x: 1673, y: 224 },
        { x: 1634, y: 485 },
      ],
      small: [
        { x: 256, y: 596 },
        { x: 164, y: 767 },
        { x: 328, y: 787 },
        { x: 630, y: 730 },
        { x: 235, y: 954 },
        { x: 837, y: 1037 },
        { x: 309, y: 1295 },
        { x: 1596, y: 297 },
        { x: 1407, y: 540 },
        { x: 1555, y: 558 },
        { x: 1280, y: 1532 },
        { x: 1822, y: 1545 },
        { x: 1460, y: 1793 },
      ],
    },
    animal: {
      penguin: [
        [
          { x: 370, y: 85 },
          { x: 113, y: 293 },
          { x: 249, y: 620 },
          { x: 576, y: 503 },
          { x: 704, y: 262 },
        ],
        [
          { x: 511, y: 1068 },
          { x: 1000, y: 1410 },
          { x: 430, y: 416 },
          { x: 960, y: 1060 },
        ],
      ],
    },
  },
  "6": {
    iceberg: {
      big: [{ x: 877, y: 1032 }],
      boomerang: [{ x: 1292, y: 725 }],
      medium: [
        { x: 833, y: 500 },
        { x: 650, y: 833 },
        { x: 1050, y: 700 },
        { x: 1230, y: 1170 },
      ],
      bean: [
        { x: 1060, y: 510 },
        { x: 1160, y: 985 },
        { x: 700, y: 1310 },
        { x: 1455, y: 1095 },
        { x: 1550, y: 1730 },
      ],
      small: [
        { x: 230, y: 645 },
        { x: 1685, y: 195 },
        { x: 1425, y: 465 },
        { x: 555, y: 1085 },
        { x: 990, y: 1390 },
        { x: 1720, y: 1470 },
      ],
    },
    animal: {
      penguin: [
        [
          { x: 865, y: 730 },
          { x: 560, y: 900 },
          { x: 860, y: 490 },
          { x: 350, y: 510 },
        ],
        [
          { x: 900, y: 1000 },
          { x: 1190, y: 265 },
          { x: 1660, y: 215 },
          { x: 1250, y: 1550 },
        ],
      ],
    },
  },
  f: {
    iceberg: {
      bean: [{ x: 455, y: 690 }],
      boomerang: [{ x: 1352, y: 392 }],
      small: [
        { x: 953, y: 197 },
        { x: 637, y: 1533 },
        { x: 1750, y: 1263 },
      ],
    },
  },
};

/** Definition of a map tile */
export interface TileDef {
  icebergDefs: icebergs.IcebergDef[];
  animalDefs: animals.AnimalDef[];
}

/** Convert shorthand tile descriptions into full TileDef format */
export const tileDefs: Record<string, TileDef> = _.mapObject(
  tileDesign,
  (tileContents) => {
    const icebergDefs: icebergs.IcebergDef[] = [];
    if ("iceberg" in tileContents) {
      for (const icebergType in tileContents.iceberg) {
        for (const posInPixels of tileContents.iceberg[
          icebergType as icebergs.IcebergType
        ]) {
          const position = new planck.Vec2(
            tools.toMeters(posInPixels.x),
            tools.toMeters(posInPixels.y)
          );
          icebergDefs.push({
            type: icebergType as icebergs.IcebergType,
            position,
          });
        }
      }
    }

    const animalDefs: animals.AnimalDef[] = [];
    if ("animal" in tileContents) {
      for (const icebergType in tileContents.animal) {
        for (const waypointList of tileContents.animal[
          icebergType as animals.AnimalType
        ]) {
          const waypoints = waypointList.map(
            (posInPixels) =>
              new planck.Vec2(
                tools.toMeters(posInPixels.x),
                tools.toMeters(posInPixels.y)
              )
          );
          animalDefs.push({
            type: icebergType as animals.AnimalType,
            waypoints,
          });
        }
      }
    }

    return {
      icebergDefs,
      animalDefs,
    };
  }
);

export class Tile extends entity.CompositeEntity {
  private _name: string;
  private _index: planck.Vec2;
  private _rotation: number; // Radians in PI/2 increments

  private _position: planck.Vec2;
  private _icebergs: icebergs.Iceberg[];
  private _animals: animals.Animal[];
  private _debugContainer: PIXI.Container;

  constructor(name: string, index: planck.Vec2, rotation: number) {
    super();

    this._name = name;
    this._index = index;
    this._rotation = rotation;
  }

  protected _setup(): void {
    this._position = planck.Vec2.mul(this._index, mapTileSize);

    const transformChain = [
      // Move the the center
      new planck.Transform(
        new planck.Vec2(-mapTileSize / 2, -mapTileSize / 2),
        0
      ),
      // Rotate
      new planck.Transform(planck.Vec2.zero(), this._rotation),
      // Move back to origin
      new planck.Transform(
        new planck.Vec2(mapTileSize / 2, mapTileSize / 2),
        0
      ),
      // Move tile to position
      new planck.Transform(this._position, 0),
    ];
    const transform = tools.chainTransforms(transformChain);

    this._icebergs = [];
    for (const def of this.def.icebergDefs) {
      // const localPos = planck.Vec2.mul(staticIcebergDef.position, mapTileSize);
      const absolutePos = planck.Transform.mulVec2(transform, def.position);
      const iceberg = new icebergs.Iceberg(absolutePos, def.type);
      this._icebergs.push(iceberg);
      this._activateChildEntity(
        iceberg,
        entity.extendConfig({
          container: this._entityConfig.icebergContainer,
        })
      );
    }

    this._animals = [];
    for (const def of this.def.animalDefs) {
      const absoluteWaypoints = def.waypoints.map((localPos) =>
        planck.Transform.mulVec2(transform, localPos)
      );
      const iceberg = new animals.Animal(def.type, absoluteWaypoints);
      this._animals.push(iceberg);
      this._activateChildEntity(
        iceberg,
        entity.extendConfig({
          container: this._entityConfig.animalContainer,
        })
      );
    }

    if (settings.inDebugMode()) {
      this._debugContainer = new PIXI.Container();
      this._entityConfig.container.addChild(this._debugContainer);

      const boundaries = new PIXI.Graphics();
      boundaries
        .lineStyle(2, 0x000000, 0.5)
        .drawRect(
          tools.toPixels(this._position.x),
          tools.toPixels(this._position.y),
          tools.toPixels(mapTileSize),
          tools.toPixels(mapTileSize)
        );
      this._debugContainer.addChild(boundaries);

      const tileNameText = new PIXI.Text(`Tile ${this._name}`);
      tileNameText.position = tools.toPixiPoint(this._position);
      this._entityConfig.container.addChild(tileNameText);
    }
  }

  protected _teardown(): void {
    // Child entities will be removed implicitly
    this._icebergs = [];
    this._animals = [];

    if (this._debugContainer)
      this._entityConfig.container.removeChild(this._debugContainer);
  }

  get position(): planck.Vec2 {
    return this._position;
  }

  get def(): TileDef {
    return tileDefs[this._name];
  }
}
