import * as _ from "underscore";
import * as PIXI from "pixi.js";

import * as entity from "booyah/src/entity";
import * as easing from "booyah/src/easing";
import * as tween from "booyah/src/tween";

import * as database from "./database";
import * as assets from "./assets";
import * as waste from "./waste";
import * as tools from "./tools";
import * as settings from "./settings";
import { translate } from "./tools";

export class EndScreen extends entity.CompositeEntity {
  private _container: PIXI.Container;

  protected _setup(): void {
    // Pick up the score from the entering transition
    console.assert(
      _.has(this._enteringTransition.params, "score"),
      "Score not transited from level to game_over"
    );
    console.assert(
      _.has(this._enteringTransition.params, "wastes"),
      "Wastes not transited from level to game_over"
    );
    console.assert(
      _.has(this._enteringTransition.params, "time"),
      "Time not transited from level to game_over"
    );

    const score: number = this._enteringTransition.params.score;
    const wastes: Record<waste.WasteName, number> =
      this._enteringTransition.params.wastes;
    const time: number = this._enteringTransition.params.time;
    const duration = score > 0 ? (score > 500 ? 2000 : 1000) : 0;

    this._container = new PIXI.Container();
    this._entityConfig.container.addChild(this._container);

    tools.fx(this, "end");

    this._container.addChild(
      tools.rect({
        color: tools.blueSea,
        width: tools.screenSize.x,
        height: tools.screenSize.y,
      })
    );

    this._container.addChild(
      // Darken the game
      tools.rect({
        color: 0x000000,
        alpha: 0.3,
        width: tools.screenSize.x,
        height: tools.screenSize.y,
      })
    );

    let globalScoreLabel: PIXI.Text, globalScoreText: PIXI.Text;
    let leaderboardText: PIXI.Text;
    const popup = this._container.addChild(
      tools.roundedSprite(this, "rounded_100_white", 100, (popup, center) => {
        popup.scale.set(0.5);
        popup.width = tools.screenSize.x * 0.8 * 2;
        popup.height = tools.screenSize.y * 0.8 * 2;

        center();

        popup.position.x += tools.screenCenter.x;
        popup.position.y += tools.screenCenter.y - 60;

        // Title
        popup.addChild(
          tools.text(
            ` ${tools.translate("game_over")} `,
            {
              fill: "black",
              fontFamily: "Nexa Heavy",
              fontSize: 150,
            },
            (title) => {
              title.position.set(popup.width / 2, 100);
              title.anchor.set(0.5, 0);
            }
          )
        );

        popup.addChild(
          tools.text(
            ` ${tools.translate("total")} `,
            {
              fill: "black",
              fontFamily: "Nexa Heavy",
              fontSize: 100,
            },
            (it) => {
              it.position.set(popup.width / 2 - 200, 400);
              it.anchor.set(1, 0.5);
            }
          )
        );

        // Player Score
        popup.addChild(
          tools.text(
            ` 0 pts `,
            {
              fill: "red",
              fontFamily: "Nexa Heavy",
              fontSize: 150,
            },
            (it) => {
              it.position.set(popup.width / 2 - 200, 400);
              it.anchor.set(0, 0.5);

              this._activateChildEntity(
                new tween.Tween({
                  from: 0,
                  to: score,
                  duration,
                  easing: easing.easeOutCirc,
                  onUpdate: (value: number) => {
                    it.text = ` ${Math.floor(value).toLocaleString()} pts `;
                  },
                })
              );
            }
          )
        );

        // Wastes
        popup.addChild(
          tools.roundedSprite(this, "rounded_17_cyan", 17, (embed) => {
            const scale = 4;

            embed.scale.set(scale);
            embed.width = (popup.width * 0.9) / scale;
            embed.height = (popup.height * 0.25) / scale;
            embed.position.set(popup.width * 0.05, 600);

            embed.addChild(
              tools.text(
                ` ${tools.translate("recycled_wastes")} `,
                {
                  fill: "white",
                  fontFamily: assets.Fonts.Ubuntu,
                  fontSize: 18,
                },
                (it) => {
                  it.position.x = embed.width / 2;
                  it.position.y = 20;
                }
              )
            );

            let i = 0;
            for (const wasteName in wastes) {
              embed.addChild(
                tools.sprite(this, wasteName as waste.WasteName, (waste) => {
                  waste.scale.set(0.5);
                  waste.position.set(50 + 160 * i, embed.height / 2);

                  waste.addChild(
                    tools.text(
                      ` ${wastes[
                        wasteName as waste.WasteName
                      ].toLocaleString()} `,
                      {
                        fill: "white",
                        fontFamily: "Nexa Heavy",
                        fontSize: 70,
                      },
                      (it) => {
                        it.anchor.set(0, 0.5);
                        it.position.x = 60;
                        it.position.y = 28;
                      }
                    )
                  );
                })
              );

              i++;
            }
          })
        );

        globalScoreLabel = popup.addChild(
          tools.text(
            ` ${translate("total_points")} `,
            {
              fill: "black",
              fontFamily: assets.Fonts.Ubuntu,
              fontSize: 70,
            },
            (it) => {
              it.position.set(popup.width / 2, popup.height - 660);
              it.anchor.set(0.5, 0);
              it.visible = false;
            }
          )
        );

        globalScoreText = popup.addChild(
          tools.text(
            "",
            {
              fill: "red",
              fontFamily: assets.Fonts.NexaHeavy,
              fontSize: 250,
            },
            (it) => {
              it.position.set(popup.width / 2, popup.height - 600);
              it.anchor.set(0.5, 0);

              it.visible = false;
            }
          )
        );

        leaderboardText = popup.addChild(
          tools.text(
            "",
            {
              fill: "black",
              fontFamily: assets.Fonts.Ubuntu,
              fontSize: 180,
            },
            (it) => {
              it.position.set(popup.width / 2, popup.height - 270);
              it.anchor.set(0.5, 0);
              it.visible = false;
            }
          )
        );
      })
    );

    // Retry Button
    {
      const button = new PIXI.NineSlicePlane(
        this._entityConfig.app.loader.resources[
          assets.images["rounded_button_44"].pathname
        ].texture,
        44,
        44,
        44,
        44
      );
      this._container.addChild(button);

      button.interactive = true;
      button.buttonMode = true;

      const text = tools.text(` ${translate("try_again").toUpperCase()} `, {
        fill: "white",
        fontFamily: assets.Fonts.Nexa,
        fontSize: 70,
      });
      button.addChild(text);

      // Dynamically size button to contain the text
      button.width = text.width + 20;
      button.height = 96;

      // Position button to the right
      // (for some reason the popup is scaled by 2?)
      button.position.set(
        popup.position.x + popup.width / 2 - button.width,
        930
      );
      // button.position.set(1670 - button.width, 930);

      // Position text within the button
      text.position.set(button.width / 2, button.height / 2);
      text.anchor.set(0.5);

      // Handle hover effects
      this._once(button, "pointertap", () => {
        tools.fx(this, "button");

        this._transition = entity.makeTransition();
      });

      this._on(button, "pointerover", () => {
        button.texture =
          this._entityConfig.app.loader.resources[
            assets.images["rounded_button_44_hover"].pathname
          ].texture;
        text.style.fill = 0xf72717;
      });
      this._on(button, "pointerout", () => {
        button.texture =
          this._entityConfig.app.loader.resources[
            assets.images["rounded_button_44"].pathname
          ].texture;
        text.style.fill = "white";
      });
    }

    // Send the scores to the database
    database
      .fetchGlobalScore()
      .then((previousGlobalScore: number) => {
        if (!this.isSetup) return;

        const globalScore = score + previousGlobalScore;

        this._activateChildEntity(
          new entity.EntitySequence([
            new entity.WaitingEntity(duration),
            new entity.FunctionCallEntity(() => {
              globalScoreText.visible = true;
              globalScoreLabel.visible = true;
            }),
            new tween.Tween({
              from: previousGlobalScore,
              to: globalScore,
              duration,
              easing: easing.easeOutCirc,
              onUpdate: (value: number) => {
                globalScoreText.text = ` ${Math.floor(
                  value
                ).toLocaleString()} pts `;
              },
            }),
          ])
        );

        let name = settings.getName();
        if (typeof name === "undefined" || name === "unknown") {
          name = prompt("Enter your name for the leaderboard") || "unknown";
          settings.setName(name as string);
        }

        return database.addScore(score, time, name as string);
      })
      .then((placeInLeaderboard: number) => {
        if (typeof placeInLeaderboard === "undefined") return;

        leaderboardText.text = `You are #${placeInLeaderboard} in the leaderboard`;
        leaderboardText.visible = true;
      })
      .catch(console.error);
  }

  protected _teardown(): void {
    this._entityConfig.container.removeChild(this._container);
  }
}

export class LeaderboardScreen extends entity.CompositeEntity {
  private _container: PIXI.Container;

  protected _setup(): void {
    this._container = new PIXI.Container();
    this._entityConfig.container.addChild(this._container);

    tools.fx(this, "end");

    this._container.addChild(
      tools.rect({
        color: tools.blueSea,
        width: tools.screenSize.x,
        height: tools.screenSize.y,
      })
    );

    this._container.addChild(
      // Darken the game
      tools.rect({
        color: 0x000000,
        alpha: 0.3,
        width: tools.screenSize.x,
        height: tools.screenSize.y,
      })
    );

    const popup = this._container.addChild(
      tools.roundedSprite(this, "rounded_100_white", 100, (popup, center) => {
        popup.scale.set(0.5);
        popup.width = tools.screenSize.x * 0.8 * 2;
        popup.height = tools.screenSize.y * 0.8 * 2;

        center();

        popup.position.x += tools.screenCenter.x;
        popup.position.y += tools.screenCenter.y - 60;
      })
    );

    // Title
    popup.addChild(
      tools.text(
        ` Leaderboard `,
        {
          fill: "black",
          fontFamily: "Nexa Heavy",
          fontSize: 150,
        },
        (title) => {
          title.position.set(popup.width / 2, 100);
          title.anchor.set(0.5, 0);
        }
      )
    );

    const loadingText = new PIXI.Text("Loading...", {
      fill: "black",
      fontFamily: "Nexa Extra Light",
      fontSize: 150,
    });
    loadingText.anchor.set(0.5);
    loadingText.position.set(popup.width / 2, popup.height / 2);
    popup.addChild(loadingText);

    database.getLeaderboard().then((records) => {
      popup.removeChild(loadingText);

      {
        const names = records
          .map((record, index) => `${index + 1})\t${record.name}`)
          .join("\n");

        const namesText = new PIXI.Text(names, {
          fill: "black",
          fontFamily: "Nexa Extra Light",
          fontSize: 80,
          lineHeight: 130,
          align: "right",
        });
        namesText.anchor.set(1, 0);
        namesText.position.set(popup.width / 2 - 50, 320);
        popup.addChild(namesText);
      }

      {
        const scores = records.map((x) => `${x.score} pts`).join("\n");
        const scoresText = new PIXI.Text(scores, {
          fill: 0xd10007,
          fontFamily: "Nexa Heavy",
          fontSize: 80,
          lineHeight: 130,
        });
        scoresText.position.set(popup.width / 2 + 50, 320);
        popup.addChild(scoresText);
      }
    });

    // Retry Button
    {
      const button = new PIXI.NineSlicePlane(
        this._entityConfig.app.loader.resources[
          assets.images["rounded_button_44"].pathname
        ].texture,
        44,
        44,
        44,
        44
      );
      this._container.addChild(button);

      button.interactive = true;
      button.buttonMode = true;

      const text = tools.text(` ${translate("try_again").toUpperCase()} `, {
        fill: "white",
        fontFamily: assets.Fonts.Nexa,
        fontSize: 70,
      });
      button.addChild(text);

      // Dynamically size button to contain the text
      button.width = text.width + 20;
      button.height = 96;

      // Position button to the right
      // (for some reason the popup is scaled by 2?)
      button.position.set(
        popup.position.x + popup.width / 2 - button.width,
        930
      );
      // button.position.set(1670 - button.width, 930);

      // Position text within the button
      text.position.set(button.width / 2, button.height / 2);
      text.anchor.set(0.5);

      // Handle hover effects
      this._once(button, "pointertap", () => {
        tools.fx(this, "button");

        this._transition = entity.makeTransition();
      });

      this._on(button, "pointerover", () => {
        button.texture =
          this._entityConfig.app.loader.resources[
            assets.images["rounded_button_44_hover"].pathname
          ].texture;
        text.style.fill = 0xf72717;
      });
      this._on(button, "pointerout", () => {
        button.texture =
          this._entityConfig.app.loader.resources[
            assets.images["rounded_button_44"].pathname
          ].texture;
        text.style.fill = "white";
      });
    }
  }

  protected _teardown(): void {
    this._entityConfig.container.removeChild(this._container);
  }
}
