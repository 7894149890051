import * as _ from "underscore";
import * as PIXI from "pixi.js";
import * as planck from "planck";

import * as entity from "booyah/src/entity";
import * as geom from "booyah/src/geom";

import * as map from "./map";
import * as tools from "./tools";
import * as assets from "./assets";
import * as settings from "./settings";

export type AnimalType = "dolphin" | "penguin" | "whale";

interface AnimalSettings {
  speed: number;
  radius: number;
  sfx: assets.FXName;
}

const animalSettings: Record<AnimalType, AnimalSettings> = {
  dolphin: {
    speed: 14,
    radius: 2,
    sfx: "dolphin",
  },
  penguin: {
    speed: 9,
    radius: 1,
    sfx: "penguin",
  },
  whale: {
    speed: 7,
    radius: 4,
    sfx: "whale",
  },
};

const animalMaxTurnAngle = Math.PI / 100;

/** Definition of a moving iceberg within a tile  */
export interface AnimalDef {
  type: AnimalType;
  waypoints: planck.Vec2[];
}

/**
 * A MovingIceberg will move from one waypoint to another at a given speed, while still staying on a tile.
 * The positions are global on the map
 */
export class Animal extends entity.CompositeEntity {
  private _type: AnimalType;
  private _waypoints: planck.Vec2[];

  private _waypointIndex: number;
  private _container: PIXI.Container;
  private _body: planck.Body;
  private _stateMachine: entity.StateMachine;

  public readonly fxName: assets.FXName;

  constructor(type: AnimalType, waypoints: planck.Vec2[]) {
    super();

    this._type = type;
    this._waypoints = waypoints;
    this.fxName = animalSettings[type].sfx;
  }

  protected _setup(): void {
    // Setup physics
    const userData: map.BodyUserData = {
      type: "animal",
      entity: this,
    };

    this._waypointIndex = _.random(this._waypoints.length);

    this._body = this._entityConfig.world.createBody({
      type: "kinematic",
      position: this._waypoints[this._waypointIndex],
      userData,
    });
    this._body.createFixture({
      shape: new planck.Circle(this.radius),
      density: 1,
      restitution: 1,
      filterCategoryBits: map.BodyCategoryBits.animal,
    });

    // Setup graphics
    this._container = new PIXI.Container();
    this._container.position = tools.toPixiPoint(this._body.getPosition());
    this._entityConfig.container.addChild(this._container);

    const swimmingAnimation = new tools.AnimatedSpriteEntity(
      assets.spritesheets[
        (this._type + "_swim") as assets.SpriteSheetName
      ].pathname,
      {
        loop: true,
        animationSpeed: 25 / 60,
        anchor: new PIXI.Point(0.5, 0.5),
        rotation: Math.PI / 2,
      }
    );
    const hitAnimation = new tools.AnimatedSpriteEntity(
      assets.spritesheets[
        (this._type + "_hit") as assets.SpriteSheetName
      ].pathname,
      {
        animationSpeed: 25 / 60,
        anchor: new PIXI.Point(0.5, 0.5),
        rotation: Math.PI / 2,
      }
    );

    if (settings.inDebugMode()) {
      const graphics = new PIXI.Graphics();
      graphics.beginFill(0xffffff, 0.5);
      graphics.drawCircle(0, 0, tools.toPixels(this.radius));
      graphics.endFill();
      this._container.addChild(graphics);
    }

    this._stateMachine = new entity.StateMachine(
      {
        move: new entity.ParallelEntity([
          new entity.EntitySequence(
            [
              new entity.FunctionalEntity({
                update: () => {
                  this._moveTowardsNextWaypoint();
                  this._updateGraphics();
                },
                requestTransition: () => this._isNearNextWaypoint(),
                teardown: () => this._advanceWaypointIndex(),
              }),
            ],
            { loop: true }
          ),
          swimmingAnimation,
        ]),
        hit: new entity.ParallelEntity([
          new entity.FunctionCallEntity(() => this._stopMovement()),
          hitAnimation,
        ]),
      },
      {
        startingState: "move",
        transitions: {
          // move doesn't complete by itself
          hit: "move",
        },
      }
    );
    this._activateChildEntity(
      this._stateMachine,
      entity.extendConfig({ container: this._container })
    );
  }

  protected _teardown(): void {
    this._entityConfig.world.destroyBody(this._body);
    delete this._body;

    this._entityConfig.container.removeChild(this._container);
    delete this._container;
  }

  get nextWaypoint(): planck.Vec2 {
    return this._waypoints[(this._waypointIndex + 1) % this._waypoints.length];
  }

  takeCollision(): void {
    if (_.last(this._stateMachine.visitedStates).name !== "move") return;

    this._stateMachine.changeState("hit");
  }

  private _updateGraphics(): void {
    this._container.position = tools.toPixiPoint(this._body.getPosition());
    this._container.rotation = this._body.getAngle();
  }

  private _advanceWaypointIndex(): void {
    this._waypointIndex = (this._waypointIndex + 1) % this._waypoints.length;
  }

  private _isNearNextWaypoint(): boolean {
    const distance = planck.Vec2.distance(
      this._body.getPosition(),
      this.nextWaypoint
    );
    return distance <= this.speed;
  }

  private _stopMovement(): void {
    this._body.setLinearVelocity(planck.Vec2.zero());
  }

  private _moveTowardsNextWaypoint(): void {
    const targetVector = planck.Vec2.sub(
      this.nextWaypoint,
      this._body.getPosition()
    );
    const targetAngle = Math.atan2(targetVector.y, targetVector.x);
    const newAngle = geom.moveTowardsAngle(
      this._body.getAngle(),
      targetAngle,
      animalMaxTurnAngle
    );
    this._body.setAngle(newAngle);

    const speed = animalSettings[this._type].speed;
    const velocity = new planck.Vec2(
      Math.cos(newAngle) * speed,
      Math.sin(newAngle) * speed
    );
    this._body.setLinearVelocity(velocity);
  }

  get radius(): number {
    return animalSettings[this._type].radius;
  }

  get speed(): number {
    return animalSettings[this._type].speed;
  }
}
