import * as entity from "booyah/src/entity";
import * as tween from "booyah/src/tween";
import * as easing from "booyah/src/easing";

import * as PIXI from "pixi.js";

import * as tools from "./tools";

const tutorialWaitTime = 5000;

type TutorialId =
  | "tuto_control_joystick"
  | "tuto_control_keyboard"
  | "tuto_waste"
  | "tuto_bonus"
  | "tuto_obstacle"
  | "tuto_lives";

class Tutorial extends entity.CompositeEntity {
  private _displayed: boolean;
  private _container: PIXI.Container;

  /** If _waitTime is provided, will close after that amount of time */
  constructor(private _textId: TutorialId, private _waitTime?: number) {
    super();
  }

  protected _setup() {
    this._displayed = false;

    this._container = new PIXI.Container();
    this._container.position.set(
      tools.screenSize.x / 2,
      tools.screenSize.y + 100
    );

    this._buildTutorial();

    this._activateChildEntity(
      new tween.Tween({
        from: tools.screenSize.y + 100,
        to: tools.screenSize.y - 100,
        duration: 1000,
        easing: easing.easeOutBack,
        onUpdate: (y) => {
          this._container.position.y = y;
        },
        onTeardown: () => {
          this._displayed = true;
        },
      })
    );

    this._entityConfig.container.addChild(this._container);

    if (typeof this._waitTime !== "undefined") {
      this._activateChildEntity(
        new entity.EntitySequence([
          new entity.WaitingEntity(this._waitTime),
          new entity.FunctionCallEntity(() => this.close()),
        ])
      );
    }
  }

  protected _teardown() {
    this._entityConfig.container.removeChild(this._container);
  }

  close(): void {
    this._activateChildEntity(
      new entity.EntitySequence([
        // Wait until the tutorial is shown (previous tween completes)
        new entity.FunctionalEntity({
          requestTransition: () => this._displayed,
        }),
        new tween.Tween({
          from: this._container.position.y,
          to: this._container.position.y + 300,
          duration: 500,
          easing: easing.easeInBack,
          onUpdate: (y) => {
            this._container.position.y = y;
          },
          onTeardown: () => {
            this._transition = entity.makeTransition();
          },
        }),
      ])
    );
  }

  private _buildTutorial() {
    const text = tools.translate(this._textId);

    const width = Math.min(
      tools.screenSize.x,
      tools.proportion(text.length, 0, 30, 400, 1200)
    );
    const scale = 0.5;
    const circlePosition = (-width * scale) / 2 + 50;
    const circleScale = 0.85;

    this._container.addChild(
      tools.roundedSprite(this, "rounded_100_white", 100, (it, center) => {
        it.alpha = 0.2;
        it.tint = 0x000000;
        it.width = width + 100;
        it.height = 200;
        it.scale.set(scale);

        center();

        it.position.y += 5;
        it.position.x += 25;
      }),

      tools.roundedSprite(this, "rounded_100_white", 100, (it, center) => {
        it.width = width + 100;
        it.height = 200;
        it.scale.set(scale);

        center();

        it.position.x += 25;
      }),

      tools.formattedText(
        text,
        {
          wordWrap: true,
          breakWords: false,
          wordWrapWidth: tools.screenSize.x * 0.4,
        },
        (it) => {
          it.anchor.set(0, 0.5);
          it.position.x += (-width * scale) / 2 + 100;
        }
      ),

      ...(this._textId === "tuto_bonus" || this._textId === "tuto_lives"
        ? [
            tools.circle(
              { color: tools.red, radius: 40 * circleScale },
              (it) => (it.position.x = circlePosition)
            ),

            tools.circle(
              { color: 0xffffff, radius: 30 * circleScale },
              (it) => (it.position.x = circlePosition)
            ),

            tools.circle(
              { color: tools.red, radius: 25 * circleScale },
              (it) => (it.position.x = circlePosition)
            ),

            tools.circle(
              { color: 0xffffff, radius: 20 * circleScale },
              (it) => (it.position.x = circlePosition)
            ),
          ]
        : [
            tools.sprite(this, this._textId, (it) => {
              it.anchor.set(0.5);
              it.position.x = circlePosition;
            }),
          ])
    );
  }
}

export class TutorialSequence extends entity.CompositeEntity {
  protected _setup() {
    const introTutorialTouch = new Tutorial(
      "tuto_control_joystick",
      tutorialWaitTime
    );
    const introTutorialKeyboard = new Tutorial("tuto_control_keyboard");
    const collectTutorial = new Tutorial("tuto_waste");
    const obstacleTutorial = new Tutorial("tuto_obstacle", tutorialWaitTime);
    const livesTutorial = new Tutorial("tuto_lives", tutorialWaitTime);
    const fillTutorial = new Tutorial("tuto_bonus");

    // Did the player press any keys yet?
    let didUseInput = false;

    this._activateChildEntity(
      new entity.StateMachine(
        {
          start: new entity.ParallelEntity([
            new entity.EntitySequence([
              introTutorialTouch,
              // If the player already pressed a key, skip the rest of the sequence
              () =>
                didUseInput
                  ? new entity.TransitoryEntity()
                  : introTutorialKeyboard,
            ]),
            new entity.FunctionCallEntity(() => {
              this._once(this._entityConfig.level.map.boat, "turnTo", () => {
                didUseInput = true;

                if (introTutorialTouch.isSetup) introTutorialTouch.close();
                else if (introTutorialKeyboard.isSetup)
                  introTutorialKeyboard.close();
              });
            }),
          ]),
          "wait-collect": new entity.ParallelEntity([
            collectTutorial,
            new entity.FunctionCallEntity(() => {
              this._once(this._entityConfig.level, "change:score", () =>
                collectTutorial.close()
              );
            }),
          ]),
          obstacle: obstacleTutorial,
          lives: livesTutorial,
          "wait-fill": new entity.ParallelEntity([
            fillTutorial,
            new entity.FunctionCallEntity(() => {
              this._once(this._entityConfig.level.map.boat.net, "isFull", () =>
                fillTutorial.close()
              );
            }),
          ]),
        },
        {
          transitions: {
            start: "wait-collect",
            "wait-collect": "obstacle",
            obstacle: "lives",
            lives: "wait-fill",
            "wait-fill": "end",
          },
        }
      )
    );
  }
}
