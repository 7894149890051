import * as PIXI from "pixi.js";
import * as planck from "planck";
import * as _ from "underscore";

import * as entity from "booyah/src/entity";
import * as geom from "booyah/src/geom";

import * as map from "./map";
import * as net from "./net";
import * as tools from "./tools";
import * as settings from "./settings";

const initialBoatSpeed = 10;

const maxTurnAngle = Math.PI / 200;
const invulerableTime = 2000;
const bounceWithDamageTime = 400; // Time in ms while "boucing back" after collision
const bounceWithoutDamageTime = 400;
const boatWidth = 6; // In meters

export { invulerableTime };

export type BoatState =
  | "normal"
  | "bounceWithDamage"
  | "bounceWithoutDamage"
  | "invulnerable"
  | "dead";

export class Boat extends entity.CompositeEntity {
  private _speed: number;
  private _position: planck.Vec2;
  private _direction: number; // Angle in radians
  private _net: net.Net;
  private _netRope: tools.FreeSprite;
  private _stateMachine: entity.StateMachine;
  private _boatWaves: entity.AnimatedSpriteEntity;
  private _container: PIXI.Container;
  private _netRopeContainer: PIXI.Container;
  private _body: planck.Body;

  constructor(position: planck.Vec2) {
    super();

    this._position = position;
  }

  _setup() {
    this._speed = initialBoatSpeed;

    tools.playFxLoop(this, "boat", 0.25);

    // Setup physics
    const userData: map.BodyUserData = {
      type: "boat",
      entity: this,
    };
    const filterCategoryBits = map.BodyCategoryBits.boat;
    const filterMaskBits =
      map.BodyCategoryBits.waste |
      map.BodyCategoryBits.net |
      map.BodyCategoryBits.iceberg |
      map.BodyCategoryBits.animal |
      map.BodyCategoryBits.boundary;

    this._body = this._entityConfig.world.createBody({
      type: "dynamic",
      position: this._position,
      linearDamping: 0.1,
      userData,
    });
    const backShape = planck.Box(
      boatWidth / 2,
      boatWidth / 2,
      new planck.Vec2(boatWidth / 2, 0)
    );
    this._body.createFixture({
      shape: backShape,
      density: 1,
      filterCategoryBits,
      filterMaskBits,
      // friction: 0.3,
    });
    const frontShape = planck.Circle(
      new planck.Vec2(boatWidth, 0),
      boatWidth / 2
    );
    this._body.createFixture({
      shape: frontShape,
      density: 1,
      filterCategoryBits,
      filterMaskBits,
      // friction: 0.3,
    });

    // Setup rendering
    this._direction = 0;

    this._container = new PIXI.Container();
    this._entityConfig.container.addChild(this._container);

    this._boatWaves = tools.animatedSprite(
      this,
      "boat_waves",
      {
        resetFrame: true,
      },
      (it) => {
        it.sprite.loop = true;
        it.sprite.animationSpeed = 25 / 60;
        it.sprite.anchor.set(0.5, 0.65);
        it.sprite.rotation = Math.PI / 2;
      }
    );

    this._activateChildEntity(
      this._boatWaves,
      entity.extendConfig({
        container: this._entityConfig.seaContainer,
      })
    );

    // Boat
    this._activateChildEntity(
      tools.animatedSprite(
        this,
        "boat",
        {
          resetFrame: true,
        },
        (it) => {
          it.sprite.loop = true;
          it.sprite.animationSpeed = 25 / 60;
          it.sprite.anchor.set(0.5, 0.9);
          it.sprite.rotation = Math.PI / 2;

          this._activateChildEntity(
            tools.animatedSprite(
              this,
              "boat_trail",
              {
                resetFrame: true,
              },
              (trail) => {
                trail.sprite.loop = true;
                trail.sprite.animationSpeed = 25 / 60;
                trail.sprite.anchor.set(0.5, 0);
              }
            ),
            entity.extendConfig({
              container: it.sprite,
            })
          );
        }
      ),
      entity.extendConfig({
        container: this._container,
      })
    );

    // Draw net
    this._net = new net.Net();
    this._activateChildEntity(this._net);

    // Draw net rope
    this._netRope = this._container.addChild(
      tools.freeSprite(this, "net_rope", (it) => {
        it.completion = 1;
        it.anchor.set(0.5, 0);
        it.angle = 90;

        this._on(this._net, "completionUpdated", (completion) => {
          it.completion = completion;
        });
      })
    );

    if (settings.inDebugMode()) {
      // Show what is simulated
      const sprite = new PIXI.Graphics();

      // Back shape
      sprite.beginFill(0xffffff, 0.5);
      sprite.drawPolygon(
        tools.toPixiPoint(backShape.getVertex(0)),
        tools.toPixiPoint(backShape.getVertex(1)),
        tools.toPixiPoint(backShape.getVertex(2)),
        tools.toPixiPoint(backShape.getVertex(3))
      );
      sprite.endFill();

      // Front shape
      sprite.beginFill(0xffffff, 0.5);
      sprite.drawCircle(
        tools.toPixiPoint(frontShape.getCenter()).x,
        tools.toPixiPoint(frontShape.getCenter()).y,
        tools.toPixels(frontShape.getRadius())
      );
      sprite.endFill();

      this._container.addChild(sprite);
    }

    this._stateMachine = new entity.StateMachine(
      {
        normal: new entity.FunctionalEntity({
          update: () => this._moveForward(),
        }),
        bounceWithDamage: () =>
          new entity.ParallelEntity([
            new entity.FunctionCallEntity(() => {
              this._container.children.forEach((it) => {
                it.alpha = 0;
              });

              this._activateChildEntity(
                new entity.EntitySequence([
                  tools.animatedSprite(
                    this,
                    "boat_hit",
                    { transitionOnComplete: true },
                    (it) => {
                      it.sprite.loop = false;
                      it.sprite.animationSpeed = 25 / 60;
                      it.sprite.anchor.set(0.5, 0.9);
                      it.sprite.rotation = Math.PI / 2;
                    }
                  ),
                  new entity.FunctionCallEntity(() => {
                    this._container.children.forEach((it) => {
                      it.alpha = 1;
                    });
                  }),
                ]),
                entity.extendConfig({
                  container: this._container,
                })
              );
            }),
            new tools.WaitingEntity(bounceWithDamageTime),
          ]),
        bounceWithoutDamage: new tools.WaitingEntity(bounceWithoutDamageTime),
        invulnerable: new tools.WaitingEntity(invulerableTime),
      },
      {
        startingState: "normal",
        transitions: {
          bounceWithDamage: "invulnerable",
          bounceWithoutDamage: "normal",
          invulnerable: "normal",
        },
        endingStates: ["dead"],
      }
    );

    this._activateChildEntity(
      this._stateMachine,
      entity.extendConfig({
        container: this._container,
      })
    );
  }

  _update(): void {
    // Update graphics
    this._position = this._body.getPosition();
    this._container.position = tools.toPixiPoint(this._position);
    this._container.rotation = this._direction;

    // Update waves
    this._boatWaves.sprite.position = tools.toPixiPoint(this._position);
    this._boatWaves.sprite.rotation =
      this._direction + geom.degreesToRadians(90);

    // Update physics
    this._body.setAngle(this._direction);

    // Update net rope direction
    const p1 = this._position;
    const p2 = this._net.position;
    this._netRope.rotation =
      geom.degreesToRadians(-90) +
      Math.atan2(p2.y - p1.y, p2.x - p1.x) -
      this._direction;
  }

  _moveForward(): void {
    const movement = new planck.Vec2(
      this._speed * Math.cos(this._direction),
      this._speed * Math.sin(this._direction)
    );
    this._body.setLinearVelocity(movement);
    this._body.setAngularVelocity(0);
  }

  get state(): BoatState {
    return _.last(this._stateMachine.visitedStates).name as BoatState;
  }

  get net(): net.Net {
    return this._net;
  }

  get body(): planck.Body {
    return this._body;
  }

  turnTo(angle: number): void {
    // The framerate multiplier will be based on 120 fps
    const framerateMultiplier =
      this._lastFrameInfo.timeSinceLastFrame / (1000 / 120);

    const clampedAngle = geom.moveTowardsAngle(
      this._direction,
      angle,
      maxTurnAngle * framerateMultiplier
    );
    this._direction = geom.normalizeAngle(clampedAngle);

    this.emit("turnTo", angle);
  }

  turnBy(angle: number): void {
    this.turnTo(this._direction + angle);
  }

  /** Returns true if collision is taken into account, else false */
  takeCollision(takeDamage: boolean): boolean {
    if (this.state !== "normal") return false;

    if (takeDamage) {
      this._stateMachine.changeState("bounceWithDamage");
      this.emit("crash");
    } else {
      this._stateMachine.changeState("bounceWithoutDamage");
    }

    return true;
  }

  levelUp(): void {
    this._speed += 1;
    console.log("boat speed", this._speed);
  }

  get position(): planck.Vec2 {
    return this._position;
  }

  get direction(): number {
    return this._direction;
  }

  kill(): void {
    this._stateMachine.changeState("dead");
  }

  protected _teardown(): void {
    tools.stopFx(this, "boat");

    this._entityConfig.world.destroyBody(this._body);
    delete this._body;

    this._entityConfig.container.removeChild(this._container);
    delete this._container;
  }
}
