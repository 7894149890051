import * as planck from "planck";
import * as PIXI from "pixi.js";
import * as _ from "underscore";

import * as entity from "booyah/src/entity";
import * as geom from "booyah/src/geom";
import * as util from "booyah/src/util";

import * as iceberg from "./iceberg";
import * as animals from "./animal";
import * as assets from "./assets";
import * as waste from "./waste";
import * as tools from "./tools";
import * as settings from "./settings";
import * as tile from "./tile";
import * as boat from "./boat";
import * as net from "./net";

export interface BodyCategories {
  boat: boat.Boat;
  net: net.Net;
  iceberg: iceberg.Iceberg;
  animal: animals.Animal;
  waste: waste.Waste;
  boundary: Map;
}

export enum BodyCategoryBits {
  boat = 1 << 0,
  net = 1 << 1,
  iceberg = 1 << 2,
  animal = 1 << 3,
  boundary = 1 << 4,
  waste = 1 << 5,
}

export type BodyType = keyof BodyCategories;

export interface BodyUserData {
  type: BodyType;
  entity: BodyCategories[BodyType];
}

type CollisionHandler = (
  aUserData: BodyUserData,
  bUserData: BodyUserData
) => void;

export const initialWasteCountPerTile = 10;

const physicsTimeStep = 1000 / 60;

const mapSize = tile.mapTileSize * 5;
const buoyDistance = 200;

const timeBetweenAnimalSounds = 8000;

const startingTile = "empty";

export class Map extends entity.CompositeEntity {
  private _container: PIXI.Container;
  private _seaContainer: PIXI.Container;
  private _wasteContainer: PIXI.Container;
  private _animalContainer: PIXI.Container;
  private _icebergContainer: PIXI.Container;
  private _boatContainer: PIXI.Container;
  private _skyContainer: PIXI.Container;
  private _boat: boat.Boat;
  private _tiles: tile.Tile[];
  private _boundaryBody: planck.Body;
  private _wastes: waste.Waste[];

  // Physics
  private _world: planck.World;
  private _collisionContacts: planck.Contact[];
  // Collision handlers use a key of typeA-typeB
  private _collisionHandlers: Partial<
    Record<`${BodyType}-${BodyType}`, CollisionHandler>
  >;
  private _elapsedTime: number;
  private _lastPhysicsTime: number;

  protected _setup(): void {
    console.assert(Seagull.count === 0);

    this._elapsedTime = 0;
    this._lastPhysicsTime = 0;
    this._world = planck.World({});
    this._world.on("pre-solve", this._onPreSolveCollision.bind(this));
    this._setupCollisionHandlers();

    this._container = new PIXI.Container();

    this._seaContainer = new PIXI.Container();
    this._wasteContainer = new PIXI.Container();
    this._animalContainer = new PIXI.Container();
    this._icebergContainer = new PIXI.Container();
    this._boatContainer = new PIXI.Container();
    this._skyContainer = new PIXI.Container();

    this.container.addChild(
      this._seaContainer,
      this._animalContainer,
      this._icebergContainer,
      this._wasteContainer,
      this._boatContainer,
      this._skyContainer
    );

    this._entityConfig.container.addChild(this._container);

    this._tiles = [];
    this._generateTiles();

    this._createBoundaries();

    this._createBoat();

    this._wastes = [];
    this.regenerateWastes();

    this._processSFX();
  }

  protected _update(): void {
    // Run enough fixed physics steps to catch up with the current time
    // Based on https://gafferongames.com/post/fix_your_timestep/
    this._elapsedTime += this._lastFrameInfo.timeSinceLastFrame;
    while (this._lastPhysicsTime < this._elapsedTime) {
      this._simulatePhysicsStep();
      this._lastPhysicsTime += physicsTimeStep;
    }

    // Center the camera on the boat
    const screenSize = new PIXI.Point(
      this._entityConfig.app.view.width,
      this._entityConfig.app.view.height
    );
    this._container.position.set(
      screenSize.x / 2 - tools.toPixels(this._boat.position.x),
      screenSize.y / 2 - tools.toPixels(this._boat.position.y)
    );

    // Launch Seagull
    if (
      Seagull.count < Seagull.maxCount &&
      Math.random() < Seagull.appearRate
    ) {
      this._activateChildEntity(
        new Seagull(),
        entity.extendConfig({
          container: this._skyContainer,
          getCenter: () =>
            new PIXI.Point(
              tools.toPixels(this._boat.position.x),
              tools.toPixels(this._boat.position.y)
            ),
        })
      );
    }
  }

  protected _teardown(): void {
    this._world.destroyBody(this._boundaryBody);
    delete this._boundaryBody;

    this._container.removeChild(this._container);
    this._container = null;
  }

  /** Run one step of the physics simulation */
  private _simulatePhysicsStep(): void {
    this._collisionContacts = [];
    // Planck.js takes its physics time step in seconds
    this._world.step(physicsTimeStep / 1000);
    this._processCollisionContacts();
  }

  get boat(): boat.Boat {
    return this._boat;
  }

  get container(): PIXI.Container {
    return this._container;
  }

  /** Regenerate wastes on the map and return the number created */
  regenerateWastes(wasteCountPerTile = initialWasteCountPerTile): number {
    const oldWasteCount = this._wastes.length;
    const newWasteCount =
      wasteCountPerTile * this._tiles.length - oldWasteCount;
    if (newWasteCount <= 0) return 0; // Nothing to do

    const newWastePerTileCount = Math.ceil(newWasteCount / this._tiles.length);
    for (const thisTile of this._tiles) {
      const tilePos = thisTile.position;
      for (let i = 0; i < newWastePerTileCount; i++) {
        const offset = new planck.Vec2(
          _.random(tile.mapTileSize),
          _.random(tile.mapTileSize)
        );
        const wastePos = planck.Vec2.add(tilePos, offset);
        const wasteName = waste.getRandomWasteName();
        const wasteRadius = waste.wasteDefs[wasteName].radius;

        const trash = new waste.Waste(wastePos, wasteRadius, wasteName);
        this._wastes.push(trash);
        this._activateChildEntity(
          trash,
          entity.extendConfig({
            container: this._wasteContainer,
            world: this._world,
          })
        );
      }
    }

    const createdWasteCount = this._wastes.length - oldWasteCount;
    console.log("Created", createdWasteCount, "wastes");
    return createdWasteCount;
  }

  private _generateTiles(): void {
    const mapTileCount = mapSize / tile.mapTileSize;
    const middleTileIndex = Math.floor(mapTileCount / 2);

    // Find all the tile names except the empty one
    const nonEmptyTileNames = _.difference(Object.keys(tile.tileDefs), [
      "empty",
    ]);

    for (let i = 0; i < mapTileCount; i++) {
      for (let j = 0; j < mapTileCount; j++) {
        const index = new planck.Vec2(i, j);

        let tileName: string;
        let rotation: number;
        if (middleTileIndex === i && middleTileIndex === j) {
          // Put an empty tile in the middle
          tileName = startingTile;
          rotation = 0;
        } else {
          // Pick a random non-empty tile and rotation
          tileName = util.randomArrayElement(nonEmptyTileNames);

          // Temporarily disable rotations as they make certain icebergs too close visuallt
          // rotation = _.random(3) * (Math.PI / 2);
          rotation = 0;
        }

        const thisTile = new tile.Tile(tileName, index, rotation);
        this._tiles.push(thisTile);
        this._activateChildEntity(
          thisTile,
          entity.extendConfig({
            container: this._seaContainer,
            icebergContainer: this._icebergContainer,
            animalContainer: this._animalContainer,
            world: this._world,
          })
        );
      }
    }
  }

  private _createBoat(): void {
    this._boat = new boat.Boat(new planck.Vec2(mapSize / 2, mapSize / 2));
    this._activateChildEntity(
      this._boat,
      entity.extendConfig({
        container: this._boatContainer,
        seaContainer: this._seaContainer,
        world: this._world,
      })
    );
    this._on(this._boat.net, "released", this._onReleased);
  }

  private _createBoundaries(): void {
    // Create a chain that surrounds the map boundaries
    const chainShape = new planck.Chain(
      [
        new planck.Vec2(0, 0),
        new planck.Vec2(mapSize, 0),
        new planck.Vec2(mapSize, mapSize),
        new planck.Vec2(0, mapSize),
      ],
      true
    );
    const userData: BodyUserData = {
      type: "boundary",
      entity: this,
    };
    this._boundaryBody = this._world.createBody({
      userData,
    });
    this._boundaryBody.createFixture({
      shape: chainShape,
      density: 0,
      restitution: 0.75,
      filterCategoryBits: BodyCategoryBits.boundary,
    });

    const mapSizeInPixels = tools.toPixels(mapSize);

    // Dispose buoys around the map limits each X px
    for (let pixels = 0; pixels < mapSizeInPixels; pixels += buoyDistance) {
      const shift = new PIXI.Point(
        Math.random() * 20 - 10,
        Math.random() * 20 - 10
      );

      const borders = [
        // top border
        tools.animatedSprite(
          this,
          `buoy_${Math.random() > 0.5 ? 1 : 0}`,
          {},
          (it) => {
            it.sprite.position.set(pixels, 0);
          }
        ),

        // bottom border
        tools.animatedSprite(
          this,
          `buoy_${Math.random() > 0.5 ? 1 : 0}`,
          {},
          (it) => {
            it.sprite.position.set(mapSizeInPixels - pixels, mapSizeInPixels);
          }
        ),

        // left border
        tools.animatedSprite(
          this,
          `buoy_${Math.random() > 0.5 ? 1 : 0}`,
          {},
          (it) => {
            it.sprite.position.set(0, pixels);
          }
        ),

        // right border
        tools.animatedSprite(
          this,
          `buoy_${Math.random() > 0.5 ? 1 : 0}`,
          {},
          (it) => {
            it.sprite.position.set(mapSizeInPixels, mapSizeInPixels - pixels);
          }
        ),
      ];

      borders.forEach((it) => {
        it.sprite.anchor.set(0.5);
        it.sprite.position.x += shift.x;
        it.sprite.position.y += shift.y;
        it.sprite.animationSpeed = 25 / 60;
      });

      this._activateChildEntity(
        new entity.ParallelEntity(borders),
        entity.extendConfig({
          container: this._icebergContainer,
        })
      );
    }

    if (settings.inDebugMode()) {
      // For graphics, a line will do for now
      const lineGraphics = new PIXI.Graphics();
      lineGraphics.lineStyle({ width: 1 });
      lineGraphics
        .moveTo(0, 0)
        .lineTo(tools.toPixels(mapSize), 0)
        .lineTo(tools.toPixels(mapSize), tools.toPixels(mapSize))
        .lineTo(0, tools.toPixels(mapSize))
        .lineTo(0, 0);
      this._seaContainer.addChild(lineGraphics);
    }
  }

  private _onReleased(wasteInNet: waste.WasteInNet): void {
    const trash = new waste.Waste(
      wasteInNet.worldPosition,
      wasteInNet.radius,
      waste.getRandomWasteName()
    );
    this._wastes.push(trash);
    this._activateChildEntity(
      trash,
      entity.extendConfig({
        container: this._wasteContainer,
        world: this._world,
      })
    );
  }

  private _onPreSolveCollision(
    contact: planck.Contact,
    oldManifold: planck.Manifold
  ): void {
    this._collisionContacts.push(contact);
  }

  private _setupCollisionHandlers(): void {
    // Prepare table of collision handlers
    this._collisionHandlers = {};
    this._addCollisionHandler("animal", "net", this._handleAnimalNetCollision);
    this._addCollisionHandler(
      "boat",
      "animal",
      this._handleBoatAnimalCollision
    );
    this._addCollisionHandler(
      "boat",
      "boundary",
      this._handleBoatBoundaryCollision
    );
    this._addCollisionHandler(
      "boat",
      "iceberg",
      this._handleBoatIcebergCollision
    );
    this._addCollisionHandler("boat", "waste", this._handleWasteCollision);
    this._addCollisionHandler("net", "waste", this._handleWasteCollision);
    this._addCollisionHandler(
      "iceberg",
      "net",
      this._handleNetIcebergCollision
    );
  }

  private _addCollisionHandler(
    typeA: BodyType,
    typeB: BodyType,
    handler: CollisionHandler
  ): void {
    if (_.has(this._collisionHandlers, `${typeA}-${typeB}`))
      console.warn(`Overwriting collision handler for types ${typeA}-${typeB}`);

    this._collisionHandlers[`${typeA}-${typeB}`] = handler.bind(this);
    // Reverse order of the arguments
    this._collisionHandlers[`${typeB}-${typeA}`] = (
      typeA: BodyUserData,
      typeB: BodyUserData
    ) => handler.call(this, typeB, typeA);
  }

  private _processSFX() {
    this._activateChildEntity(
      new entity.EntitySequence(
        [
          new entity.WaitingEntity(timeBetweenAnimalSounds),
          new entity.FunctionCallEntity(() => {
            const elements = new Set<assets.FXName>();

            // check displayed items
            this._world.queryAABB(
              new planck.AABB(
                new planck.Vec2(
                  this._boat.position.x - tools.toMeters(tools.screenCenter.x),
                  this._boat.position.y - tools.toMeters(tools.screenCenter.y)
                ),
                new planck.Vec2(
                  this._boat.position.x + tools.toMeters(tools.screenCenter.x),
                  this._boat.position.y + tools.toMeters(tools.screenCenter.y)
                )
              ),
              (fixture) => {
                const bodyUserData = fixture
                  .getBody()
                  .getUserData() as BodyUserData;

                if (bodyUserData.entity instanceof animals.Animal) {
                  elements.add(bodyUserData.entity.fxName);
                }

                return true;
              }
            );

            // play a random item SFX
            if (elements.size > 0) {
              tools.fx(this, tools.random([...elements]));
            }
          }),
        ],
        {
          loop: true,
        }
      )
    );
  }

  private _processCollisionContacts(): void {
    for (const contact of this._collisionContacts) {
      const bodyAUserData = contact
        .getFixtureA()
        .getBody()
        ?.getUserData() as BodyUserData;

      const bodyBUserData = contact
        .getFixtureB()
        .getBody()
        ?.getUserData() as BodyUserData;

      if (!bodyAUserData || !bodyBUserData) continue;

      const handler =
        this._collisionHandlers[`${bodyAUserData.type}-${bodyBUserData.type}`];
      if (handler) {
        handler(bodyAUserData, bodyBUserData);
      }
    }
  }

  private _handleBoatIcebergCollision(
    bodyAUserData: BodyUserData,
    bodyBUserData: BodyUserData
  ): void {
    if (!this._boat.takeCollision(true)) return;

    tools.fx(this, "hit_static_obstacle");
  }

  private _handleBoatAnimalCollision(
    bodyAUserData: BodyUserData,
    bodyBUserData: BodyUserData
  ): void {
    if (!this._boat.takeCollision(true)) return;

    tools.fx(this, "hit_static_obstacle");

    Array.from(arguments)
      .find((param) => {
        return param.entity instanceof animals.Animal;
      })
      ?.entity.takeCollision();
  }

  private _handleBoatBoundaryCollision(
    bodyAUserData: BodyUserData,
    bodyBUserData: BodyUserData
  ): void {
    if (!this._boat.takeCollision(false)) return;

    this._entityConfig.fxMachine.play(
      assets.fxTracks["hit_static_obstacle"].pathname
    );
  }

  private _handleNetIcebergCollision(
    bodyAUserData: BodyUserData,
    bodyBUserData: BodyUserData
  ): void {
    if (!this._boat.net.takeCollision()) return;

    tools.fx(this, "hit_static_obstacle");
  }

  private _handleAnimalNetCollision(
    bodyAUserData: BodyUserData,
    bodyBUserData: BodyUserData
  ): void {
    if (!this._boat.net.takeCollision()) return;

    tools.fx(this, "hit_moving_obstacle");

    Array.from(arguments)
      .find((param) => {
        return param.entity instanceof animals.Animal;
      })
      ?.entity.takeCollision();
  }

  private _handleWasteCollision(
    bodyAUserData: BodyUserData,
    bodyBUserData: BodyUserData
  ): void {
    const wasteUserData = Array.from(arguments).find((param) => {
      return param.type === "waste";
    });

    const wasteEntity = wasteUserData.entity as waste.Waste;
    const wasteIndex = this._wastes.indexOf(wasteEntity);
    if (wasteIndex === -1) return; // Something is wrong, maybe an extra collision?

    if (!this.boat.net.collectTrash(wasteEntity)) return; // The net might not be able to collect right now

    this._deactivateChildEntity(wasteEntity);
    this._wastes.splice(wasteIndex, 1);
  }
}

class Seagull extends entity.CompositeEntity {
  static readonly baseSpeed = 0.1;
  static readonly minLifeTime = 2000;
  static readonly appearRate = 0.002;
  static readonly maxCount = 2;
  static count = 0;

  private _sprite: PIXI.Sprite;
  private _shadow: PIXI.Sprite;
  private _scale: number;
  private _angle: number;
  private _speed: number;
  private _launchedAt: number;
  private _position: PIXI.Point;
  private _turnSpeed: number;

  private get center(): PIXI.Point {
    return this._entityConfig.getCenter();
  }

  protected _setup(frameInfo: entity.FrameInfo): void {
    this._launchedAt = frameInfo.playTime;
    this._scale = 1; // Math.random() * 0.5 + 0.5;
    this._speed = Seagull.baseSpeed / (this._scale / 2);
    this._turnSpeed = Math.random() * 0.001 - 0.0005;

    this._setupPosition();
    this._setupAngle();

    this._sprite = tools.sprite(this, "seagull", (it) => {
      it.anchor.set(0.5);
      it.scale.set(this._scale);
      it.rotation = this._angle + geom.degreesToRadians(90);
    });

    this._shadow = tools.sprite(this, "seagull_shadow", (it) => {
      it.anchor.set(0.5);
      it.scale.set(this._scale * 1.1);
      it.rotation = this._angle + geom.degreesToRadians(90);
    });

    this._entityConfig.container.addChild(this._shadow, this._sprite);

    Seagull.count++;
  }

  protected _update(frameInfo: entity.FrameInfo): void {
    // move to angle (with delta-time)
    const speed = this._speed * frameInfo.timeSinceLastFrame;
    this._angle += this._turnSpeed * frameInfo.timeSinceLastFrame;

    this._position.x += Math.cos(this._angle) * speed;
    this._position.y += Math.sin(this._angle) * speed;

    this._sprite.position = this._position;
    this._shadow.position.x = this._position.x - 30 * this._scale;
    this._shadow.position.y = this._position.y + 30 * this._scale;

    this._sprite.rotation = this._angle + geom.degreesToRadians(90);
    this._shadow.rotation = this._angle + geom.degreesToRadians(90);

    // if min lifetime not reached, don't check for screen-out
    if (frameInfo.playTime < this._launchedAt + Seagull.minLifeTime) return;

    // if screen-out, teardown
    if (
      this._position.x <
        this.center.x - tools.screenCenter.x - this._sprite.width ||
      this._position.x >
        this.center.x + tools.screenCenter.x + this._sprite.width ||
      this._position.y <
        this.center.y - tools.screenCenter.y - this._sprite.height ||
      this._position.y >
        this.center.y + tools.screenCenter.y + this._sprite.height
    ) {
      this._transition = entity.makeTransition();
    }
  }

  protected _teardown(): void {
    Seagull.count--;

    this._entityConfig.container.removeChild(this._shadow, this._sprite);
  }

  private _setupPosition(): void {
    this._position = new PIXI.Point();

    const rdm = Math.random();

    if (rdm < 0.25) {
      // from left
      this._position.x = this.center.x - tools.screenCenter.x;
      this._position.y =
        this.center.y +
        (Math.random() * tools.screenSize.y - tools.screenCenter.y);
    } else if (rdm < 0.5) {
      // from right
      this._position.x = this.center.x + tools.screenCenter.x;
      this._position.y =
        this.center.y +
        (Math.random() * tools.screenSize.y - tools.screenCenter.y);
    } else if (rdm < 0.75) {
      // from top
      this._position.x =
        this.center.x +
        (Math.random() * tools.screenSize.x - tools.screenCenter.x);
      this._position.y = this.center.y - tools.screenCenter.y;
    } else {
      // from bottom
      this._position.x =
        this.center.x +
        (Math.random() * tools.screenSize.x - tools.screenCenter.x);
      this._position.y = this.center.y + tools.screenCenter.y;
    }
  }

  private _setupAngle(): void {
    // angle from position to center
    this._angle = Math.atan2(
      this.center.y - this._position.y,
      this.center.x - this._position.x
    );
  }
}
