import * as entity from "booyah/src/entity";

import * as PIXI from "pixi.js";
import * as planck from "planck";

import * as settings from "./settings";
import * as tools from "./tools";
import * as map from "./map";

export const wasteDefs = {
  baril: {
    radius: tools.toMeters(30),
    score: 100,
  },
  bottle: {
    radius: tools.toMeters(25),
    score: 25,
  },
  can: {
    radius: tools.toMeters(15),
    score: 25,
  },
  plastic_bag: {
    radius: tools.toMeters(25),
    score: 50,
  },
} as const;

export type WasteName = keyof typeof wasteDefs;

export function getRandomWasteName() {
  return tools.random(Object.keys(wasteDefs) as WasteName[]);
}

export class Waste extends entity.CompositeEntity {
  private _container: PIXI.Container;
  private _body: planck.Body;

  constructor(
    private _position: planck.Vec2,
    private _radius: number,
    private _name: WasteName
  ) {
    super();
  }

  get name(): WasteName {
    return this._name;
  }

  protected _setup(): void {
    // Setup physics
    const userData: map.BodyUserData = {
      type: "waste",
      entity: this,
    };
    this._body = this._entityConfig.world.createBody({
      type: "dynamic",
      position: this._position,
      angle: Math.random() * 2 * Math.PI, // Random orientation
      linearDamping: 1,
      angularDamping: 1,
      userData,
    });
    this._body.createFixture(new planck.Circle(this._radius), {
      density: 1,
      filterCategoryBits: map.BodyCategoryBits.waste,
    });

    // Setup graphics
    this._container = new PIXI.Container();
    this._container.position = tools.toPixiPoint(this._position);
    this._entityConfig.container.addChild(this._container);

    // Sprite
    this._activateChildEntity(
      tools.animatedSprite(
        this,
        `waste_${this._name}`,
        {
          resetFrame: true,
        },
        (it) => {
          it.sprite.anchor.set(0.5);
          it.sprite.angle = -20 + Math.random() * 20;
          it.sprite.animationSpeed = 25 / 60;
          it.sprite.gotoAndPlay(tools.random(it.sprite.totalFrames));
        }
      ),
      entity.extendConfig({
        container: this._container,
      })
    );

    if (settings.inDebugMode()) {
      const graphics = new PIXI.Graphics();
      graphics.beginFill(0x00ff00, 0.5);
      graphics.drawCircle(0, 0, tools.toPixels(this._radius));
      graphics.endFill();
      this._container.addChild(graphics);
    }
  }

  protected _update(): void {
    this._position = this._body.getPosition();
    this._container.position = tools.toPixiPoint(this._position);
    this._container.rotation = this._body.getAngle();
  }

  protected _teardown(): void {
    this._entityConfig.world.destroyBody(this._body);
    delete this._body;

    this._entityConfig.container.removeChild(this._container);
    delete this._container;
  }

  get radius(): number {
    return this._radius;
  }

  get position(): planck.Vec2 {
    return this._body.getPosition();
  }
}

export class WasteInNet extends entity.CompositeEntity {
  private _container: PIXI.Container;
  private _force: planck.Vec2;

  get name() {
    return this._name;
  }

  constructor(
    private _position: planck.Vec2,
    private _radius: number,
    private _name: WasteName
  ) {
    super();
  }

  protected _setup(): void {
    this._force = new planck.Vec2();

    this._container = new PIXI.Container();
    this._container.position = tools.toPixiPoint(this._position);
    this._container.rotation = Math.random() * 2 * Math.PI; // Random orientation
    this._entityConfig.container.addChild(this._container);

    // Sprite
    this._activateChildEntity(
      tools.animatedSprite(this, `picked_up_${this._name}`, {}, (it) => {
        it.sprite.animationSpeed = 25 / 60;
        it.sprite.anchor.set(0.5);
      }),
      entity.extendConfig({
        container: this._container,
      })
    );

    if (settings.inDebugMode()) {
      const debugSprite = new PIXI.Graphics();
      debugSprite.beginFill(0x00ff00, 0.5);
      debugSprite.drawCircle(0, 0, tools.toPixels(this._radius));
      debugSprite.endFill();

      this._container.addChild(debugSprite);
    }
  }

  protected _teardown(): void {
    this._entityConfig.container.removeChild(this._container);
  }

  get radius(): number {
    return this._radius;
  }

  get position(): planck.Vec2 {
    return this._position;
  }

  get worldPosition(): planck.Vec2 {
    return this._entityConfig.net.body.getWorldPoint(this._position);
  }

  addForce(force: planck.Vec2): void {
    this._force.add(force);
  }

  applyForces(): void {
    if (this._force.x === 0 && this._force.y === 0) return;

    // // Add force to position, but keep within net
    const netRadius = this._entityConfig.net.radius;
    this._position = this._position.clone().add(this._force).clamp(netRadius);

    this._container.position = tools.toPixiPoint(this._position);

    // Reset accumulated force
    this._force = new planck.Vec2();
  }
}
