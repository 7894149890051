import * as PIXI from "pixi.js";
import * as _ from "underscore";
import UAParser from "ua-parser-js";

import * as entity from "booyah/src/entity";
import * as easing from "booyah/src/easing";
import * as tween from "booyah/src/tween";
import * as util from "booyah/src/util";

import * as tools from "./tools";
import * as assets from "./assets";
import * as runner from "./runner";

export const languages = ["en", "fr", "es", "pt"] as const;
export type Language = typeof languages[number];

export interface SettingData {
  music: number;
  sound: number;
  debug: boolean;
  name: string;
}

const defaultSettings = {
  sound: 0.5,
  music: 0.5,
};

export function getLocalSettings(): Partial<SettingData> {
  return (
    JSON.parse(localStorage.getItem("swisslog-polycycle")) ||
    _.extend({}, defaultSettings)
  );
}

export function setLocalSettings(settings: Partial<SettingData>) {
  localStorage.setItem("swisslog-polycycle", JSON.stringify(settings));
}

export function getName(): string | unknown {
  return getLocalSettings().name;
}

export function setName(value: string) {
  const settings = getLocalSettings();
  settings.name = value;
  setLocalSettings(settings);
}

const searchParams = new URL(window.location.href).searchParams;
export function inDebugMode() {
  return searchParams.has("debug") || getLocalSettings().debug || false;
}

export function isRunningOnMobile() {
  return new UAParser().getDevice().type === "mobile";
}

function getFirstSupportedLanguage(choices: string[]): Language | undefined {
  for (const choice of choices) {
    if (choice && languages.find((x) => x === choice))
      return choice as Language;
  }
}

/** Just return English in this version */
export function getLanguage(): Language {
  return "en";

  // const languageChoices = [
  //   // Priority to the language in the link
  //   searchParams.get("lang"),
  //   // Then the browser language
  //   navigator.language?.slice(0, 2),
  //   // Fallback to English
  //   "en",
  // ];
  // return getFirstSupportedLanguage(languageChoices);
}

export function getMaxFps(): number {
  if (searchParams.has("maxfps")) return parseInt(searchParams.get("maxfps"));
  else return 0;
}

export function installSettingsButton(
  rootConfig: entity.EntityConfig,
  rootEntity: entity.ParallelEntity
) {
  const button = new SettingsButton();
  rootConfig.settingsButton = button;
  rootEntity.addChildEntity(button);
}

export class SettingsButton extends entity.CompositeEntity {
  private _settingsMenu: SettingsMenu;
  private _button: PIXI.Sprite;

  protected _setup(): void {
    this._button = tools.sprite(this, "settings");
    this._entityConfig.containerAbove.addChild(this._button);

    this._button.anchor.set(1, 0);
    this._button.position.set(tools.screenSize.x, 10);
    this._button.interactive = true;
    this._button.buttonMode = true;
    this._on(this._button, "pointertap", this._open);
  }

  protected _teardown(): void {
    this._entityConfig.containerAbove.removeChild(this._button);
  }

  protected _onSignal(frameInfo: entity.FrameInfo, signal: string) {
    super._onSignal(frameInfo, signal);

    if (signal === "pause") this._open();
  }

  private _open(): void {
    if (this._settingsMenu) return; // Settings already open

    // Change graphic of button
    const pressedButton = tools.sprite(this, "settings_pressed");
    pressedButton.anchor.set(1, 0);
    this._button.addChild(pressedButton);

    // Create the settings menu
    this._settingsMenu = new SettingsMenu();

    // Pause the game
    // This might cause _open() to be called a second time, so it's important to create the settingsMenu before
    runner.changeGameState("paused");

    // Show the settings menu
    this._activateChildEntity(
      new entity.EntitySequence([
        this._settingsMenu,

        // When the menu closes, unpause the game
        new entity.FunctionCallEntity(() => {
          this._button.removeChild(pressedButton);
          delete this._settingsMenu;

          runner.changeGameState("playing");
        }),
      ])
    );
  }
}

export class SettingsMenu extends entity.CompositeEntity {
  private _bg: PIXI.Graphics;
  private _container: PIXI.Container;
  private _data = getLocalSettings();

  protected _setup() {
    this._container = new PIXI.Container();
    this._container.position.copyFrom(tools.screenCenter);
    this._container.scale.set(0);
    this._entityConfig.containerAbove.addChild(this._container);

    this._build();
    this._open();
  }

  protected _teardown() {
    this._entityConfig.containerAbove.removeChild(this._container);
    delete this._container;
  }

  private _build() {
    this._bg = this._container.addChild(
      // Darken the game
      tools.rect(
        {
          color: 0x000000,
          width: tools.screenSize.x,
          height: tools.screenSize.y,
        },
        (it, center) => {
          center();

          it.alpha = 0;
          it.interactive = true;

          this._once(it, "pointertap", this._close);
        }
      ),

      // Settings box shadow
      tools.roundedSprite(this, "rounded_100_white", 100, (it, center) => {
        it.alpha = 0.1;
        it.tint = 0x000000;
        it.width = 1460;
        it.height = 900;
        it.scale.set(isRunningOnMobile() ? 1 : 0.7);

        center();

        it.position.y += 10;
      }),

      // Settings box
      tools.roundedSprite(this, "rounded_100_white", 100, (it, center) => {
        it.width = 1450;
        it.height = 900;
        it.interactive = true;
        it.scale.set(isRunningOnMobile() ? 1 : 0.7);

        center();

        // Title
        it.addChild(
          tools.text(
            tools.translate("settings"),
            {
              fontSize: 100,
            },
            (txt) => {
              txt.anchor.set(0.5, 0);
              txt.position.x = it.width / 2;
              txt.position.y = 50;
              txt.interactive = true;

              this._once(txt, "pointertap", () => {
                this._once(txt, "pointertap", () => {
                  this._once(txt, "pointertap", () => {
                    this._data.debug = !this._data.debug;

                    this._onSettingUpdated();

                    console.log("Debug mode: " + this._data.debug);
                  });
                });
              });
            }
          )
        );

        // Closing cross
        it.addChild(
          tools.sprite(this, "close", (cross) => {
            cross.anchor.set(0.5, 0);
            cross.position.x = it.width - 100;
            cross.position.y = 50;
            cross.interactive = true;
            cross.buttonMode = true;

            this._once(cross, "pointertap", this._close);
          })
        );

        // Sound range
        this._activateChildEntity(
          new InputRange(
            ["sound", "sound_muted"],
            new PIXI.Point(150, 260),
            this._data.sound ?? 0.5,
            1,
            (value) => {
              this._data.sound = value;
              this._entityConfig.fxMachine.changeVolume(value);
              this._onSettingUpdated();
            }
          ),
          entity.extendConfig({
            container: it,
          })
        );

        // Music range
        this._activateChildEntity(
          new InputRange(
            ["music", "music_muted"],
            new PIXI.Point(150, 400),
            this._data.music ?? 0.5,
            1,
            (value) => {
              this._data.music = value;
              this._entityConfig.jukebox.changeVolume(value);
              this._onSettingUpdated();
            }
          ),
          entity.extendConfig({
            container: it,
          })
        );

        // Fullscreen toggle
        if (util.supportsFullscreen()) {
          this._activateChildEntity(
            new InputSwitch(
              ["fullscreen_off", "fullscreen_on"],
              new PIXI.Point(150, 540),
              util.inFullscreen(),
              (value) => {
                if (value !== util.inFullscreen()) {
                  if (value)
                    util.requestFullscreen(
                      document.getElementById("game-parent")
                    );
                  else util.exitFullscreen();

                  this._onSettingUpdated();

                  console.assert(
                    value !== util.inFullscreen(),
                    "Fullscreen state mismatch"
                  );
                }
              }
            ),
            entity.extendConfig({
              container: it,
            })
          );
        }

        // Footer
        it.addChild(
          tools.formattedText(
            // Do not translate or stylize this text!
            "Made with  ❤  by <u>Play Curious</u>\nMusic by <u>Gabriel Palmiery</u>, sound design by <u>Jean-Baptiste Mar</u>",
            {
              fontFamily: assets.Fonts.AmericanTypewriter,
              fontSize: 30,
              align: "center",
            },
            (footer) => {
              footer.position.x = it.width / 2;
              footer.position.y = it.height - 150;
              footer.scale.set(1.25);

              footer.addChild(
                tools.sprite(this, "heart", (heart) => {
                  heart.position.set(-59, -40);
                })
              );
            }
          )
        );
      })
    );
  }

  private _open() {
    this._activateChildEntity(
      new entity.EntitySequence([
        new tween.Tween({
          from: 0,
          to: 1,
          duration: 300,
          easing: easing.easeOutBack,
          onUpdate: (value) => {
            this._container.alpha = value;
            this._container.scale.set(value);
          },
        }),
        new tween.Tween({
          from: 0,
          to: 0.3,
          duration: 150,
          onUpdate: (value) => {
            this._bg.alpha = value;
          },
        }),
      ])
    );
  }

  private _close() {
    this._activateChildEntity(
      new entity.EntitySequence([
        new tween.Tween({
          from: 0.3,
          to: 0,
          duration: 150,
          onUpdate: (value) => {
            this._bg.alpha = value;
          },
        }),
        new tween.Tween({
          from: 1,
          to: 0,
          duration: 300,
          easing: easing.easeInBack,
          onUpdate: (value) => {
            this._container.scale.set(value);
            this._container.alpha = value;
          },
        }),
        new entity.FunctionCallEntity(() => {
          this._transition = entity.makeTransition();
        }),
      ])
    );
  }

  private _onSettingUpdated() {
    setLocalSettings(this._data);
  }
}

abstract class Input<T> extends entity.CompositeEntity {
  protected _container: PIXI.Container;
  protected _icon: tools.BooleanSprite;

  abstract isOn(): boolean;

  constructor(
    protected _toggleNames: [on: assets.SpriteName, off: assets.SpriteName],
    protected _position: PIXI.Point,
    protected _value: T,
    protected _onUpdate: (value: T) => void
  ) {
    super();
  }

  get value() {
    return this._value;
  }

  set value(value: T) {
    if (this._value === value) return;
    this._value = value;
    this._icon.value = this.isOn();
    this._onUpdate(value);
  }

  protected _setup() {
    this._container = new PIXI.Container();
    this._container.position.copyFrom(this._position);

    this._icon = this._container.addChild(
      tools.booleanSprite(this, this._toggleNames, (it) => {
        it.anchor.set(0.5);
        it.value = this.isOn();
      })
    );

    this._entityConfig.container.addChild(this._container);
  }

  protected _teardown() {
    this._entityConfig.container.removeChild(this._container);
  }
}

class InputRange extends Input<number> {
  private _bar: PIXI.NineSlicePlane;
  private _dot: PIXI.Sprite;
  private _clickableZone: PIXI.Container;
  private _isPointerDown: boolean;

  constructor(
    toggleNames: [on: assets.SpriteName, off: assets.SpriteName],
    position: PIXI.Point,
    value: number,
    private _maxValue: number,
    onUpdate: (value: number) => void
  ) {
    super(toggleNames, position, value, onUpdate);
  }

  isOn(): boolean {
    return this._value > 0.1;
  }

  protected _setup() {
    super._setup();

    this._bar = this._container.addChild(
      tools.roundedSprite(this, "rounded_17_cyan", 17, (it, center) => {
        it.width = 1000;
        it.height = 34;

        center();

        it.position.x = 150;
      })
    );

    this._dot = this._container.addChild(
      tools.sprite(this, "range_dot", (it) => {
        it.anchor.set(0.5);
      })
    );

    this._clickableZone = this._container.addChild(
      (() => {
        const it = new PIXI.Container();
        it.interactive = true;
        it.hitArea = new PIXI.Rectangle(this._bar.x, -50, this._bar.width, 100);

        this._on(it, "pointerup", () => (this._isPointerDown = false));
        this._on(it, "pointerout", () => (this._isPointerDown = false));
        this._on(it, "pointerdown", (event) => {
          this._isPointerDown = true;

          this._triggerPointer(
            event.data.global.x - this._bar.getGlobalPosition().x
          );
        });
        this._on(it, "pointermove", (event) => {
          if (this._isPointerDown) {
            this._triggerPointer(
              event.data.global.x - this._bar.getGlobalPosition().x
            );
          }
        });

        return it;
      })()
    );

    this._onUpdate(this._value);
    this._updateDot();
  }

  private _triggerPointer(positionX: number) {
    const barWidth = isRunningOnMobile()
      ? this._bar.width
      : 0.7 * this._bar.width;
    this.value = tools.proportion(
      positionX,
      0,
      barWidth,
      0,
      this._maxValue,
      true
    );

    this._updateDot();
  }

  private _updateDot() {
    this._dot.position.x = this._bar.x + this._value * this._bar.width;
  }
}

class InputSwitch extends Input<boolean> {
  private _onButton: PIXI.Sprite;
  private _offButton: PIXI.Sprite;

  isOn(): boolean {
    return this._value;
  }

  protected _setup() {
    super._setup();

    this._onButton = this._container.addChild(
      tools.sprite(this, "button_on", (it) => {
        it.anchor.set(0.5);
        it.position.x = 700;

        it.addChild(
          tools.text(tools.translate("yes"), { fill: 0xffffff, fontSize: 50 })
        );

        it.interactive = true;
        it.buttonMode = true;

        this._on(it, "pointertap", () => (this.value = true));
      })
    );

    this._offButton = this._container.addChild(
      tools.sprite(this, "button_off", (it) => {
        it.anchor.set(0.5);
        it.position.x = 300;

        it.addChild(
          tools.text(tools.translate("no"), { fill: 0xffffff, fontSize: 50 })
        );

        it.interactive = true;
        it.buttonMode = true;

        this._on(it, "pointertap", () => (this.value = false));
      })
    );

    this._onUpdate(this._value);
  }
}
